/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.notification {
  position: relative;
  left: 1000px;
  opacity: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80px;
  box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
  margin: 0 0 20px;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: #ccc;
  color: #fcfcfc;
  transition: opacity 0.5s, left 0.5s ease-in-out; }
  .notification__title {
    font-weight: 900;
    font-size: 1rem; }
  .notification__text {
    font-size: 1.1rem;
    margin-right: 4px; }
  .notification__close-button {
    position: absolute;
    top: 5px;
    right: 7px;
    width: 20px;
    height: 20px;
    color: rgba(0, 0, 0, 0.3);
    cursor: pointer; }
    .notification__close-button:hover {
      color: rgba(0, 0, 0, 0.5); }
  .notification--red {
    background-color: #d03e61; }
  .notification--green {
    background-color: #82c498; }
  .notification--blue {
    background-color: #5479ab; }
  .notification--yellow {
    background-color: #ffd951; }
  .notification--visible {
    left: 0;
    opacity: 1; }
