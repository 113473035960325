/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.career-path {
  display: flex;
  flex-direction: column; }
  .career-path__time-periods {
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; }
  .career-path__time-period {
    position: relative;
    display: flex;
    flex-shrink: 0;
    height: 40px;
    margin: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
    padding: 0 15px 0 25px;
    background: white;
    font-weight: 500;
    line-height: 40px;
    text-transform: uppercase; }
    .career-path__time-period:not(:last-child) {
      margin-right: 25px; }
    .career-path__time-period--passed {
      border-color: green;
      background: white;
      color: green; }
    .career-path__time-period--active {
      border-color: var(--color-main-dark);
      background: var(--color-main-dark);
      color: white; }
    .career-path__time-period--locked {
      border-color: #b2b2b2;
      background: #e5e5e5;
      color: #b2b2b2; }
  .career-path__time-period-number {
    position: absolute;
    top: 8px;
    left: -11px;
    width: 22px;
    height: 22px;
    border: 2px solid;
    border-color: inherit;
    border-radius: 50%;
    background: inherit;
    color: inherit;
    line-height: 22px;
    text-align: center; }
    .career-path__time-period--passed .career-path__time-period-number {
      background: green;
      color: white; }
    .career-path__time-period--active .career-path__time-period-number {
      border-color: white; }
  .career-path__competences {
    display: flex;
    flex-direction: column; }
  .career-path__competence-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .career-path__competence-wrapper:not(:last-child) {
      margin-bottom: 30px; }
  .career-path__competence-status {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    margin-right: 20px;
    margin-left: 10px;
    border: 2px solid #b2b2b2;
    border-radius: 50%;
    background: white;
    font-size: 12.6px;
    line-height: 18px; }
    .career-path__competence-status--passed {
      border-color: green;
      background-color: #e6ffe6;
      color: green; }
      .career-path__competence-status--passed i.fas.fa-check {
        transform: translateY(1px); }
    .career-path__competence-status--close-to-deadline {
      border-color: #ef591b;
      background-color: #fff6e6; }
    .career-path__competence-status--after-deadline {
      border-color: red;
      background-color: #ffe6e6; }
  .career-path__competence-card {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: flex-start;
    box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
    border-radius: 5px;
    background: white; }
  .career-path__competence-cover {
    display: flex;
    flex-shrink: 0;
    width: 140px;
    height: 140px;
    margin: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
  .career-path__competence-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-self: stretch;
    margin: 15px; }
    .career-path__competence-content:not(:first-child) {
      margin-left: 0; }
  .career-path__competence-title {
    display: flex;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: 1.2rem; }
  .career-path__competence-description {
    display: flex;
    font-weight: 500;
    font-size: 1rem; }
  .career-path__competence-actions {
    display: flex; }
  .career-path__passed-competence-card {
    flex-grow: 1;
    border-radius: 5px;
    padding: 15px;
    background: green;
    color: white;
    font-weight: 700;
    font-size: 1.2rem; }
