/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.employee-message {
  display: flex;
  flex-direction: column;
  width: calc(100% - 310px);
  box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
  border-radius: 8px;
  padding: 20px;
  background: white; }
  .employee-message__form-wrapper {
    border-radius: 4px;
    background: #f2f2f2; }
  @media screen and (max-width: 950px) {
    .employee-message {
      width: 100%;
      margin: 30px 0; } }
