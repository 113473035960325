/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.employee-report__card {
  position: relative;
  overflow-x: auto;
  width: auto;
  box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
  margin: 1%;
  border-radius: 8px;
  padding: 40px 20px;
  background: white; }

.employee-report__title {
  min-width: 630px;
  margin-top: 0;
  margin-bottom: 20px; }

.employee-report__table {
  width: 100%;
  min-width: 630px;
  border: 0 solid;
  border-collapse: collapse;
  font-size: 0.8rem;
  table-layout: auto; }
  .employee-report__table th {
    position: relative;
    border-bottom: 1px solid #666;
    padding: 6px;
    text-align: left;
    white-space: nowrap; }
  .employee-report__table tr {
    border-bottom: 1px solid #ccc; }
    .employee-report__table tr td {
      min-width: fit-content;
      padding: 8px; }

.employee-report__buttons-wrapper {
  position: absolute;
  right: 40px;
  bottom: 40px;
  padding-top: 20px; }

.employee-report__bottom-checkbox-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 70px 0;
  padding-left: 30px; }

.employee-report__checkbox-label {
  margin-left: 10px; }

.employee-report__form {
  display: flex;
  justify-content: center; }
  .employee-report__form-inner-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 70%; }

.employee-report__divider {
  width: 100%;
  height: 1px;
  margin: 40px 0;
  background-color: #b2b2b2; }

.employee-report__input-wrapper {
  width: 45%; }

.employee-report__input {
  border-radius: 10px; }
  .employee-report__input--multiselect {
    height: 300px;
    border-color: #979797;
    padding: 10px 0 30px; }

.employee-report__status-message {
  margin: 10px;
  color: var(--color-main-dark);
  font-weight: 700;
  text-align: center; }

@media screen and (max-width: 1100px) {
  .employee-report__form-inner-wrapper {
    min-width: 80%; } }

@media screen and (max-width: 1000px) {
  .employee-report__form-inner-wrapper {
    min-width: 90%; } }

@media screen and (max-width: 950px) {
  .employee-report__form {
    min-width: 100%; }
    .employee-report__form-inner-wrapper {
      flex-direction: column;
      min-width: 70%; }
  .employee-report__bottom-checkbox-wrapper {
    padding-left: 10px; }
  .employee-report__input-wrapper {
    min-width: 100%; } }

@media screen and (max-width: 640px) {
  .employee-report__form-inner-wrapper {
    min-width: 90%; } }

.employees-page.report-page {
  width: 80%;
  min-width: fit-content;
  padding: 0; }
  @media screen and (max-width: 640px) {
    .employees-page.report-page {
      width: auto;
      min-width: auto; } }
