/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.employees-link-card {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
  border-radius: 5px;
  padding: 15px;
  background: var(--color-main-dark);
  line-height: 1.2rem; }
  .employees-link-card__label {
    display: flex;
    flex-grow: 1;
    align-items: center;
    min-width: 120px;
    padding: 0 10px;
    color: white;
    font-weight: 700;
    font-size: 1rem; }
  .employees-link-card--light {
    border: 1px solid var(--color-main-dark);
    background: #efefef; }
    .employees-link-card--light .employees-link-card__label {
      color: #444; }
  .employees-link-card a {
    display: flex;
    flex-grow: 1; }
    .employees-link-card a:active {
      text-decoration: unset; }
  .employees-link-card__count {
    color: white;
    font-weight: 700;
    font-size: 1.2rem; }
