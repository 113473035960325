/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.form-select {
  position: relative;
  padding: 7px; }
  .form-select__select {
    position: relative;
    height: 40px;
    border-radius: 10px;
    background: inherit; }
  .form-select__icon {
    position: absolute;
    right: 20px;
    bottom: 15px;
    color: #999; }
