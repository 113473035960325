/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.employees-page {
  align-items: center;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 80px; }
  .employees-page a {
    color: #0a0a0a; }
  .employees-page h1 {
    margin-left: 10px;
    font-weight: 200;
    font-size: 2rem; }
  .employees-page h2 {
    margin-top: 1em;
    margin-bottom: 0.5em;
    margin-left: 10px;
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: uppercase; }
  .employees-page__left-wrapper {
    flex: 0 0 33.33333%; }
    .employees-page__left-wrapper h2 {
      margin: 8px;
      font-weight: 700;
      font-size: 1rem;
      text-transform: initial; }
  .employees-page__section {
    margin-top: 20px; }
    .employees-page__section--message {
      display: flex;
      justify-content: space-between; }
    .employees-page__section--columns .employee-card,
    .employees-page__section--columns .employee-card__loader--expanded {
      float: left;
      width: 275px;
      max-width: 275px;
      min-height: 375px; }
  .employees-page__users-access-wrapper button {
    width: 100%;
    cursor: pointer; }
  .employees-page__users-access-wrapper ul {
    margin-left: 20px;
    padding: 0;
    list-style: none;
    text-align: left; }
    .employees-page__users-access-wrapper ul li button {
      text-align: left; }
  .employees-page__report-wrapper {
    margin-left: 20px; }
  .employees-page__grid-wrapper {
    display: flex;
    flex-direction: row; }
  .employees-page__statistics {
    display: flex;
    flex-wrap: wrap; }
    .employees-page__statistics--mobile {
      margin-bottom: 20px; }
      .employees-page__statistics--mobile .statistic-card {
        position: relative;
        box-shadow: none;
        margin: 0 15px;
        margin-bottom: 15px;
        border-radius: 0;
        padding: 0;
        background: transparent; }
        .employees-page__statistics--mobile .statistic-card .progress {
          width: 100%;
          font-size: 0.8rem; }
      .employees-page__statistics--mobile .employees-page__statistics-card-wrapper {
        flex: 1 1 100%; }
    .employees-page__statistics-mobile-title {
      width: 100%;
      margin-bottom: 0;
      padding-left: 10px;
      font-weight: 500;
      font-size: 1.2rem; }
  .employees-page__statistics-wrapper {
    flex: 0 0 66.66666%; }
  .employees-page__statistics-card-wrapper {
    display: flex;
    flex: 0 0 33.333333%;
    align-items: stretch; }
    @media screen and (max-width: 950px) {
      .employees-page__statistics-card-wrapper {
        flex: 0 0 50%; } }
  .employees-page__activity-and-users-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 3px; }
  .employees-page__activity-wrapper {
    display: flex;
    margin-bottom: 60px; }
  .employees-page__users-overview-wrapper {
    display: flex;
    flex: 1 1 33.33333%; }
    .employees-page__users-overview-wrapper > * {
      margin: 7px; }
  .employees-page__status-message {
    margin: 10px;
    color: var(--color-main-dark);
    font-weight: 700;
    text-align: center; }
  .employees-page__mobile-checklist {
    margin-top: 20px; }
  .employees-page__mobile-checklist, .employees-page__mobile-personnel {
    width: 100%;
    margin-bottom: 20px; }
  .employees-page__mobile-toggle {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 17px);
    box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
    margin-left: 7px;
    border-radius: 10px;
    padding: 15px 17px;
    background: #fff;
    color: var(--color-main-dark);
    font-weight: 400;
    font-size: 1.2rem;
    text-align: left;
    cursor: pointer; }
  @media only screen and (max-width: 768px) {
    .employees-page {
      width: 98%;
      margin-top: 0; }
      .employees-page__activity-wrapper {
        margin-bottom: 0; }
      .employees-page__grid-wrapper {
        flex-direction: column-reverse; }
      .employees-page__statistics-wrapper {
        flex: 0 0 auto; }
      .employees-page__left-wrapper {
        flex: 0 0 auto;
        margin-bottom: 25px; }
      .employees-page__report-wrapper {
        margin-left: 0; }
      .employees-page__section--columns {
        flex-direction: column; }
      .employees-page__section--edit {
        display: flex;
        align-items: center; }
        .employees-page__section--edit .employee-edit__card {
          float: none;
          margin: 15px 10px; }
      .employees-page__section--message {
        flex-direction: column;
        align-items: center; }
      .employees-page__section--preview {
        display: flex;
        align-items: center; } }
