/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

@keyframes notice-bubble {
  0% {
    transform: scale(1); }
  50% {
    background-color: #ff8300;
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

.camp-extra {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
  margin-bottom: 100px;
  padding: 40px 80px;
  position: relative; }
  .camp-extra__top-section {
    margin-bottom: 40px;
    padding-bottom: 0 !important; }
    .camp-extra__top-section-bg {
      border-bottom: 1px solid #d2d2d1;
      background-color: #e3e3e0;
      transition: max-height 0.4s ease-out;
      overflow: hidden;
      max-height: max-content;
      box-shadow: 0 2px 2px 0 rgba(100, 100, 100, 0.1); }
      .camp-extra__top-section-bg--collapsed {
        max-height: 128px !important; }
  .camp-extra__top-toggle {
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
    padding: 2px 20px;
    background-color: #e3e3e0;
    border: 1px solid #d2d2d1;
    border-top: none;
    color: #6a6868;
    transition: background-color 0.115s ease-out;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(100, 100, 100, 0.1); }
    .camp-extra__top-toggle:hover {
      background-color: #dbdbdb; }
    .camp-extra__top-toggle i {
      transform: scaleY(0.8); }
  @media screen and (max-width: 1100px) {
    .camp-extra {
      padding: 40px 20px; } }
  @media screen and (max-width: 850px) {
    .camp-extra {
      padding: 40px 4px; } }
  .camp-extra__columns {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 -40px; }
    .camp-extra__columns > * {
      padding: 0 40px; }
  .camp-extra__statistics-cards {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -80px;
    margin-bottom: 20px; }
    .camp-extra__statistics-cards > * {
      margin-right: 80px; }
    .camp-extra__statistics-cards--wrapper {
      display: flex;
      flex: 1 1 auto;
      width: 100%;
      max-width: 1300px;
      margin: 0 auto;
      margin-top: 20px; }
    @media screen and (max-width: 1100px) {
      .camp-extra__statistics-cards {
        margin: 0 -10px;
        margin-right: -40px;
        margin-bottom: 20px; }
        .camp-extra__statistics-cards > * {
          margin-right: 40px;
          padding: 0 10px; } }
    @media screen and (max-width: 850px) {
      .camp-extra__statistics-cards {
        flex-direction: column;
        margin-right: 0; }
        .camp-extra__statistics-cards > * {
          width: 100%;
          margin-right: 0; } }
  @media screen and (max-width: 1260px) {
    .camp-extra__highscore-lists {
      margin: 0 -10px; }
      .camp-extra__highscore-lists > * {
        padding: 0 10px; } }
  @media screen and (max-width: 850px) {
    .camp-extra__highscore-lists {
      flex-direction: column; }
      .camp-extra__highscore-lists > * {
        width: 100%; } }
  .camp-extra__notice-bubble i {
    position: absolute;
    top: 0;
    right: -16px;
    background-color: #999;
    width: 1.4em;
    line-height: 1.4;
    border-radius: 50%;
    text-align: center;
    overflow: visible;
    color: #fff;
    font-size: 10px;
    cursor: pointer; }
  .camp-extra__notice-bubble--warn i {
    background-color: #e84a39; }
  .camp-extra__notice-bubble--warn-animate i {
    animation-name: notice-bubble;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-delay: 0;
    animation-iteration-count: infinite;
    animation-direction: forward;
    will-change: transform, background-color; }
  .camp-extra__checkbox {
    flex: 1 1 auto;
    margin-top: 0;
    margin-bottom: 20px;
    margin-left: auto;
    color: #000;
    font-size: 1.1rem; }
  .camp-extra__logo {
    align-self: baseline;
    margin-right: auto;
    margin-bottom: 20px; }
  .camp-extra__last-updated-cards {
    position: absolute;
    max-width: 330px;
    width: max-content;
    position: absolute;
    right: -30px;
    top: -22px;
    z-index: 1000000;
    transform: translateX(100%); }
    .camp-extra__last-updated-cards__card {
      text-align: left;
      background-color: #fff;
      padding: 8px;
      padding-left: 40px;
      margin: 8px 0;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 3px;
      box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.2); }
      .camp-extra__last-updated-cards__card .camp-extra__notice-bubble i {
        animation: none;
        top: 12px;
        left: 12px;
        right: auto;
        bottom: auto; }
      .camp-extra__last-updated-cards__card__title {
        font-size: 0.9rem;
        color: #999; }
        .camp-extra__last-updated-cards__card__title--warn {
          color: #e84a39; }
      .camp-extra__last-updated-cards__card__timestamp {
        margin-top: 4px;
        font-size: 1rem;
        color: #666;
        font-weight: bold;
        line-height: 1.4; }
        .camp-extra__last-updated-cards__card__timestamp__time {
          font-weight: normal; }
      .camp-extra__last-updated-cards__card__warn {
        font-size: 1rem;
        color: #f03535;
        margin-top: 10px;
        margin-bottom: 4px; }
  .camp-extra__section-header {
    flex: 0 1 auto;
    align-self: baseline;
    margin: 60px 0;
    margin-right: auto;
    color: #6a6868;
    font-weight: 700;
    font-size: 2rem;
    position: relative; }
    .camp-extra__section-header::after {
      content: '';
      bottom: -10px;
      height: 4px;
      width: 120px;
      background-color: #ef591b;
      display: block;
      position: absolute; }
    .camp-extra__section-header-center::after {
      left: 50%;
      transform: translateX(-50%); }
    .camp-extra__section-header__section {
      display: flex;
      position: relative; }
    .camp-extra__section-header--metrics {
      margin: 40px auto;
      margin-bottom: 30px;
      position: relative; }
    .camp-extra__section-header-wrapper {
      display: flex;
      flex: 1 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin-right: 0;
      margin-left: auto; }
    .camp-extra__section-header__right {
      display: flex;
      flex: 0 0 auto;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0; }
  .camp-extra__top-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .camp-extra__link {
    display: block;
    color: #4ab7ed !important;
    font-size: 1.1rem;
    line-height: 0;
    text-align: right;
    cursor: pointer; }
  .camp-extra__excel-link {
    margin-top: 20px; }
  .camp-extra__highscore-links {
    flex: 0 0 auto;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 66px; }
  .camp-extra__dropdowns {
    position: sticky;
    top: 0;
    z-index: 3;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .camp-extra__dropdowns__bg {
      position: fixed;
      right: 0;
      left: 0;
      z-index: -1;
      display: none;
      height: 66px;
      background-color: rgba(255, 255, 255, 0.95); }
  .camp-extra__shop-select {
    flex: 0 0 auto;
    margin-top: 10px;
    margin-right: auto; }
  .camp-extra__datepicker {
    position: absolute;
    top: 10px;
    right: 0; }
  .camp-extra__chart-wrapper {
    display: flex;
    flex-direction: row;
    min-height: 280px;
    margin: 0 -80px; }
    .camp-extra__chart-wrapper .extra-chart {
      max-width: 500px;
      height: 346px;
      margin: 0 auto; }
    @media screen and (max-width: 1100px) {
      .camp-extra__chart-wrapper {
        flex-direction: column;
        margin: 0 -40px; }
        .camp-extra__chart-wrapper .extra-chart {
          width: 70%;
          height: 310px;
          margin-right: auto;
          margin-bottom: 20px;
          margin-left: auto; }
          .camp-extra__chart-wrapper .extra-chart:last-child {
            margin-bottom: 0; } }
    @media screen and (max-width: 850px) {
      .camp-extra__chart-wrapper {
        margin: 0; }
        .camp-extra__chart-wrapper .extra-chart {
          width: 100%; } }
  .camp-extra__void {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    width: fit-content;
    margin: auto;
    color: #666;
    font-size: 1.5rem; }
  .camp-extra__no-active {
    opacity: 0.4;
    margin-top: 20px;
    text-align: center; }
