/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.my-education {
  width: 100%;
  margin: 0 auto;
  padding: 10px 0; }
  .my-education__section-option {
    width: 100%;
    padding: 0; }
  .my-education .messages-list-wrapper {
    max-width: 1280px;
    margin: 0 auto; }
  .my-education .competences-list-wrapper {
    max-width: 1280px;
    margin: 0 auto; }
  .my-education .profile-top {
    width: 100%;
    height: 50px;
    margin-bottom: 70px;
    margin-top: -100px; }
    .my-education .profile-top__person-info {
      position: relative;
      max-width: 1024px;
      margin: 0 auto;
      padding-left: 20px; }
      .my-education .profile-top__person-info__cv {
        margin-top: 24px;
        margin-left: 106px; }
        .my-education .profile-top__person-info__cv a {
          float: left;
          margin-right: 10px;
          border: 1px solid #e7e7e7;
          border-radius: 8px;
          padding: 12px 24px;
          background-color: #fff;
          color: #000;
          font-size: 0.9rem; }
          .my-education .profile-top__person-info__cv a.active {
            background-color: var(--color-header-top);
            color: #FFF; }
          .my-education .profile-top__person-info__cv a .badge {
            float: right;
            width: 20px;
            height: 20px;
            margin-left: 10px;
            border-radius: 20px;
            background-color: #2f93e0;
            color: white;
            line-height: 20px;
            text-align: center; }
          .my-education .profile-top__person-info__cv a:hover {
            border: 1px solid #999;
            cursor: pointer; }
      .my-education .profile-top__person-info .person {
        margin-left: 110px; }
        .my-education .profile-top__person-info .person .person-avatar__image {
          border: 1px solid #bbb; }
        .my-education .profile-top__person-info .person .person-avatar__initials {
          color: #bbb; }
        .my-education .profile-top__person-info .person h2 {
          font-size: 1rem; }
        .my-education .profile-top__person-info .person__top-section {
          position: absolute;
          top: 10px;
          left: 20px; }
        .my-education .profile-top__person-info .person__education-status-container {
          position: relative;
          width: 250px;
          margin-top: 20px;
          color: #7f7f7f; }
        .my-education .profile-top__person-info .person .person__title {
          position: absolute;
          top: 3px;
          left: 45px;
          font-size: 0.8rem; }
    @media screen and (max-width: 768px) {
      .my-education .profile-top {
        display: none; } }
  .my-education__section {
    width: 100%;
    margin: 15px 0 0;
    transition: flex 0.3s ease-out; }
    .my-education__section:last-child {
      margin-bottom: 0; }
      @media (min-width: 992px) {
        .my-education__section:last-child {
          margin-bottom: 100px; } }
    .my-education__section__competences {
      max-width: 1024px;
      margin: 0 auto; }
    .my-education__section__title {
      margin-top: 50px;
      text-transform: uppercase; }
      .my-education__section__title h2 {
        font-weight: 500; }
    .my-education__section__option-courses {
      min-height: 200px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 20px;
      padding-top: 30px;
      background-color: #e8e8e8; }
      @media (min-width: 992px) {
        .my-education__section__option-courses {
          padding: 0; } }
      .my-education__section__option-courses .content {
        max-width: 800px;
        margin: 0 auto; }
        .my-education__section__option-courses .content h2 {
          margin-top: 10px;
          margin-bottom: 40px;
          font-size: 1.1rem; }
        .my-education__section__option-courses .content .img {
          min-height: 140px; }
        .my-education__section__option-courses .content .my-education__section__role-wrapper__card {
          border-bottom: 0; }
    .my-education__section__role-header {
      max-width: 800px;
      margin: 0 auto;
      margin-bottom: 60px;
      font-size: 0.9rem; }
      .my-education__section__role-header h1 {
        margin-bottom: 0;
        color: black;
        font-size: 1.2rem; }
    .my-education__section__role-required {
      max-width: 800px;
      margin: 0 auto; }
      .my-education__section__role-required h2 {
        margin-bottom: 30px; }
    .my-education__section__role-passed {
      max-width: 800px;
      margin: 0 auto;
      padding: 20px; }
      @media (min-width: 992px) {
        .my-education__section__role-passed {
          padding: 0; } }
      .my-education__section__role-passed h2 {
        font-size: 1.1rem;
        text-transform: uppercase; }
    .my-education__section__role-wrapper h2 {
      font-size: 1.1rem; }
    .my-education__section__role-wrapper .employees-checklist__card {
      box-shadow: none;
      background: none; }
    .my-education__section__role-wrapper__card {
      margin-bottom: 30px;
      border-bottom: 1px solid #ccc;
      padding-bottom: 50px; }
      .my-education__section__role-wrapper__card .content {
        padding-left: 10px; }
        @media (min-width: 576px) {
          .my-education__section__role-wrapper__card .content {
            padding: 0; } }
        .my-education__section__role-wrapper__card .content h2 {
          width: 100%;
          margin-top: 0;
          margin-bottom: 0;
          text-transform: inherit; }
        .my-education__section__role-wrapper__card .content .desc {
          color: #333;
          font-size: 0.8rem;
          line-height: 1.2em; }
        .my-education__section__role-wrapper__card .content .course-preview__actions {
          justify-content: left;
          margin-top: 30px; }
      .my-education__section__role-wrapper__card .sub.equals h3 {
        border-bottom: 1px solid #ccc;
        font-size: 0.9rem;
        line-height: 2rem; }
      .my-education__section__role-wrapper__card .sub.equals .competence-card-sub {
        margin-bottom: 30px;
        font-size: 0.9rem; }
        .my-education__section__role-wrapper__card .sub.equals .competence-card-sub .course-preview__actions {
          margin-top: 0; }
      .my-education__section__role-wrapper__card .img {
        width: 95%;
        min-height: 180px;
        margin-right: 20px;
        border: 1px solid white;
        border-radius: 10px;
        background-position: center;
        background-size: cover; }
    .my-education__section__role-wrapper__card-small {
      margin-bottom: 30px;
      padding: 20px; }
      .my-education__section__role-wrapper__card-small a.__download-link {
        color: #078ada;
        font-size: 0.9rem; }
      .my-education__section__role-wrapper__card-small .content {
        margin-top: 6px;
        margin-bottom: 1em;
        color: #666 !important;
        font-size: 0.8rem; }
        .my-education__section__role-wrapper__card-small .content h2 {
          width: 100%;
          min-height: 48px;
          margin-top: 0;
          margin-bottom: 0;
          font-size: 1rem;
          text-transform: inherit; }
        .my-education__section__role-wrapper__card-small .content .__download-link {
          display: block;
          margin-top: 0.4em; }
      .my-education__section__role-wrapper__card-small .img {
        display: flex;
        width: 100%;
        height: 200px;
        margin-right: 20px;
        border: 4px solid white;
        border: 9px solid white;
        background-position: center;
        background-size: cover; }
    .my-education__section__role-path {
      display: flex;
      box-shadow: 2px 2px 2px #ddd;
      margin-bottom: 30px;
      border-radius: 8px;
      padding: 20px;
      background-color: white; }
      .my-education__section__role-path h1 {
        float: left;
        width: 60%;
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 1.2rem; }
      .my-education__section__role-path .progress {
        margin-top: 0; }
  .my-education .cell.center-vertical {
    margin-top: auto;
    margin-bottom: auto; }
  .my-education .loading__image {
    min-height: 300px; }
  @media screen and (max-width: 768px) {
    .my-education {
      width: 100%; }
      .my-education__section {
        width: 100%;
        margin-top: 0; } }
