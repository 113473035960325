/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.courses-menu {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 20px; }
  .courses-menu__menu-button {
    display: flex;
    margin-right: auto;
    margin-left: 30px;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 16px;
    background: #fff;
    color: #333;
    font-weight: 600;
    font-size: 1.1em;
    cursor: pointer; }
    .courses-menu__menu-button:hover {
      border: 1px solid rgba(0, 0, 0, 0.3);
      color: #000; }
    .courses-menu__menu-button__active {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
      .courses-menu__menu-button__active:hover {
        border: 1px solid #fff;
        color: #000; }
    .courses-menu__menu-button--light {
      color: #7f7f7f; }
  .courses-menu__menu-button-icon {
    margin-right: 10px; }
  .courses-menu__competencegroups-list-wrapper {
    position: absolute;
    top: 24px;
    right: 0;
    left: 0;
    display: flex; }
  .courses-menu .sorting-active {
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 120px;
    border: 1px solid #ccc;
    padding: 2px;
    padding-right: 24px;
    white-space: nowrap;
    cursor: pointer; }
    .courses-menu .sorting-active.active {
      background-color: White; }
    .courses-menu .sorting-active .sorting-options {
      position: absolute;
      top: 100%;
      left: 0;
      display: block;
      min-width: 100%;
      box-shadow: 2px 2px 2px #ccc;
      margin: 0;
      margin-top: 5px;
      padding: 0;
      background-color: white; }
    .courses-menu .sorting-active svg {
      position: absolute;
      right: 6px;
      margin-top: 3px; }
  .courses-menu__sorting-wrapper {
    position: relative;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-right: 0;
    margin-left: auto;
    color: #666;
    font-weight: 500;
    font-size: 0.8rem;
    text-indent: 4px; }
    .courses-menu__sorting-wrapper__button {
      margin: 0 2px;
      padding: 6px 6px;
      white-space: nowrap; }
    @media screen and (max-width: 768px) {
      .courses-menu__sorting-wrapper__button {
        padding: 0;
        height: 44px;
        margin: 0; }
      .courses-menu__sorting-wrapper__sort-method-link {
        padding: 12px;
        border-bottom: 1px solid #9e9d9d; }
      .courses-menu__sorting-wrapper__sort-method-link-last {
        border-bottom: none; } }
    .courses-menu__sorting-wrapper__button,
    .courses-menu__sorting-wrapper a,
    .courses-menu__sorting-wrapper a:active,
    .courses-menu__sorting-wrapper a:hover,
    .courses-menu__sorting-wrapper a:visited {
      display: block;
      color: #4c4c4c;
      cursor: pointer; }
      .courses-menu__sorting-wrapper__button i,
      .courses-menu__sorting-wrapper a i,
      .courses-menu__sorting-wrapper a:active i,
      .courses-menu__sorting-wrapper a:hover i,
      .courses-menu__sorting-wrapper a:visited i {
        display: inline;
        padding-left: 2px; }
      .courses-menu__sorting-wrapper__button.active,
      .courses-menu__sorting-wrapper a.active,
      .courses-menu__sorting-wrapper a:active.active,
      .courses-menu__sorting-wrapper a:hover.active,
      .courses-menu__sorting-wrapper a:visited.active {
        border-bottom: 2px solid var(--color-action);
        padding-bottom: 4px;
        color: var(--color-action);
        font-weight: 800; }
        @media screen and (max-width: 768px) {
          .courses-menu__sorting-wrapper__button.active,
          .courses-menu__sorting-wrapper a.active,
          .courses-menu__sorting-wrapper a:active.active,
          .courses-menu__sorting-wrapper a:hover.active,
          .courses-menu__sorting-wrapper a:visited.active {
            border-bottom: none; }
            .courses-menu__sorting-wrapper__button.active a,
            .courses-menu__sorting-wrapper a.active a,
            .courses-menu__sorting-wrapper a:active.active a,
            .courses-menu__sorting-wrapper a:hover.active a,
            .courses-menu__sorting-wrapper a:visited.active a {
              text-decoration: underline; } }
  .courses-menu__buttons-wrapper {
    margin-top: auto; }
  .courses-menu__chipses-next {
    flex: 1 1 auto;
    margin-top: 15px;
    margin-right: auto;
    margin-left: 0; }
    .courses-menu__chipses-next .sorting-active {
      padding: 5px 7px;
      padding-right: 24px;
      font-weight: 700;
      font-size: 1rem; }
  .courses-menu__subcategory-wrapper {
    display: flex;
    flex-direction: column; }
  .courses-menu__title {
    margin-bottom: 10px;
    color: #ccc;
    font-size: 1.2rem;
    text-align: left;
    cursor: auto; }
  @media screen and (max-width: 768px) {
    .courses-menu__toggle-sort-type-icon {
      position: relative;
      left: 27px;
      bottom: 6px; }
    .courses-menu .sorting-active .sorting-options {
      border: 1px solid #9e9d9d;
      box-shadow: none; } }
  .courses-menu--mobile {
    margin-bottom: 0; }
    .courses-menu--mobile .sorting-active {
      width: auto;
      border: none;
      padding-right: 10px;
      color: #4c4c4c;
      font-weight: 400; }
      .courses-menu--mobile .sorting-active.active {
        background: transparent; }
    .courses-menu--mobile .sorting-active {
      min-width: 55px; }
    .courses-menu--mobile .courses-menu__menu-button {
      margin-left: 10px; }
    .courses-menu--mobile .courses-menu__buttons-wrapper {
      flex-direction: column-reverse;
      margin: auto 0; }
    .courses-menu--mobile .courses-menu__sorting-wrapper {
      margin: auto 0;
      margin-right: auto;
      margin-left: 10px; }
