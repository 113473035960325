/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.course-duration {
  -ms-flex-align: baseline;
  align-items: baseline;
  color: #4c4c4c;
  font-weight: bold;
  font-size: 1rem; }
  .course-duration__clock {
    margin-right: 5px; }
