/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.person-onthejob-form {
  display: flex;
  flex-direction: column; }
  .person-onthejob-form__input-wrapper {
    width: 100%; }
  .person-onthejob-form__input {
    width: 100%; }
  .person-onthejob-form__submit-button-wrapper {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding-right: 5px;
    text-align: right; }
