/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.course-card,
.course-card-loading {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%; }
  .course-card__cover,
  .course-card-loading__cover {
    position: relative; }
    .course-card__cover--image,
    .course-card-loading__cover--image {
      width: 100%;
      height: 100%;
      border: 10px solid #fff; }
  .course-card__content,
  .course-card-loading__content {
    padding: 0 10px; }

.course-card-loading .course-card__content {
  overflow: hidden !important;
  flex: 1 1 100%; }
  .course-card-loading .course-card__content svg {
    flex: 0 0 auto;
    width: 100%;
    height: 150px; }
