/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.role-form {
  display: flex;
  flex-direction: column; }
  .role-form__input-wrapper {
    width: 100%; }
  .role-form__input {
    width: 100%; }
  .role-form .form-input__label {
    display: block; }
  .role-form .form-input input[type="checkbox"] {
    width: auto;
    margin-left: 4px; }
  .role-form .form-multiselect .rw-widget {
    height: 95%; }
  .role-form__submit-button-wrapper {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding-right: 5px;
    text-align: right;
    margin-bottom: 20px; }
