/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.loading__content {
  max-width: 800px;
  margin: 0 auto; }

.loading__image {
  width: 95%;
  min-height: 300px;
  margin-top: 10px;
  margin-right: 20px;
  border: 9px solid #fff; }

.loading__title {
  width: 150px;
  height: 18px;
  margin-top: 10px;
  background: #ccc; }

.loading__text {
  width: 200px;
  height: 14px;
  margin-top: 10px;
  background: #e5e5e5; }
  .loading__text--short {
    width: 150px; }
  .loading__text--small {
    height: 8px; }

.loading-bar {
  position: fixed;
  top: -8px;
  z-index: 99999;
  width: 100%;
  height: 20px; }
  .loading-bar__progress {
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
    height: 6px;
    box-shadow: 1px 1px 3px #010031;
    margin: 0.5rem 0 1rem 0;
    border-radius: 0;
    background-color: #010031;
    background-clip: padding-box; }
  .loading-bar__indeterminate {
    background-color: #33a4df; }
    .loading-bar__indeterminate::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: inherit;
      will-change: left, right;
      -webkit-animation: indeterminate 3.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
      animation: indeterminate 3.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
    .loading-bar__indeterminate::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: inherit;
      will-change: left, right;
      -webkit-animation: indeterminate-short 3.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation: indeterminate-short 3.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      -webkit-animation-delay: 3.15s;
      animation-delay: 3.15s; }

@-webkit-keyframes indeterminate {
  0% {
    right: 100%;
    left: -35%; }
  60% {
    right: -90%;
    left: 100%; }
  100% {
    right: -90%;
    left: 100%; } }

@keyframes indeterminate {
  0% {
    right: 100%;
    left: -35%; }
  60% {
    right: -90%;
    left: 100%; }
  100% {
    right: -90%;
    left: 100%; } }

@-webkit-keyframes indeterminate-short {
  0% {
    right: 100%;
    left: -200%; }
  60% {
    right: -8%;
    left: 107%; }
  100% {
    right: -8%;
    left: 107%; } }

@keyframes indeterminate-short {
  0% {
    right: 100%;
    left: -200%; }
  60% {
    right: -8%;
    left: 107%; }
  100% {
    right: -8%;
    left: 107%; } }
