/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.dropdown-select {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-self: flex-start;
  box-shadow: 0 2px 3px 0 rgba(100, 100, 100, 0.1);
  border: 1px solid #e5e3e1;
  border-radius: 6px;
  padding: 10px 0;
  background-color: #f7f7f7;
  user-select: none;
  cursor: pointer; }
  .dropdown-select:hover {
    background-color: #fff; }
  .dropdown-select__label {
    flex: 1 1 auto;
    padding: 0 24px;
    color: #6a6868;
    font-weight: 700;
    text-align: center;
    white-space: nowrap; }
  .dropdown-select__arrow {
    display: block;
    flex: 0 0 auto;
    margin-right: 0.8em;
    text-align: center; }
  .dropdown-select__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 500;
    flex-direction: column;
    min-width: 100%;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #fff; }
    .dropdown-select__dropdown__content {
      flex-direction: column;
      flex-wrap: nowrap;
      overflow-y: auto; }
    .dropdown-select__dropdown-rightalign {
      right: 0;
      left: auto; }
    .dropdown-select__dropdown-fullwidth {
      position: absolute;
      overflow-y: auto;
      display: flex;
      flex: 0 0 100%;
      flex-direction: column;
      margin-top: 4px;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 12px;
      background-color: #fff; }
      .dropdown-select__dropdown-fullwidth-wrapper {
        position: relative;
        z-index: 500;
        width: 100%; }
    .dropdown-select__dropdown-rows .dropdown-select__dropdown__column-wrapper {
      flex-direction: column;
      flex-wrap: nowrap; }
    .dropdown-select__dropdown-rows .dropdown-select__dropdown__column__content {
      padding: 0 40px;
      padding-bottom: 14px; }
      @media screen and (max-width: 768px) {
        .dropdown-select__dropdown-rows .dropdown-select__dropdown__column__content {
          padding: 0; } }
    .dropdown-select__dropdown__column {
      flex: 1 1 auto;
      border-right: 1px solid #e9e9e9;
      padding: 8px 16px; }
      .dropdown-select__dropdown__column:last-child {
        border-right: none; }
      @media screen and (max-width: 768px) {
        .dropdown-select__dropdown__column {
          padding: 8px 8px; } }
      .dropdown-select__dropdown__column-wrapper {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        flex-wrap: wrap; }
      .dropdown-select__dropdown__column__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 10px;
        padding-left: 20px;
        color: #6a6868;
        font-weight: 700;
        font-size: 1.1em; }
      .dropdown-select__dropdown__column__content {
        display: flex;
        flex-direction: column; }
    .dropdown-select__dropdown__item {
      flex: 1 1 auto;
      min-width: min-content;
      margin: auto 0;
      border-top: 1px solid #ccc;
      padding: 10px 24px;
      font-weight: normal;
      font-size: 0.9rem;
      line-height: 1.4;
      white-space: nowrap;
      cursor: pointer; }
      .dropdown-select__dropdown__item:first-child {
        border-top: none; }
      .dropdown-select__dropdown__item:hover {
        background-color: #f7f7f7; }
      .dropdown-select__dropdown__item-active {
        font-weight: 700; }
      .dropdown-select__dropdown__item-filtered {
        color: #999; }
    .dropdown-select__dropdown-fullwidth .dropdown-select__dropdown__item {
      border-top: none; }
