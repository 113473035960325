/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.certificate {
  position: relative;
  padding: 8px 12px;
  font-size: 1.4rem;
  line-height: 1; }
  .certificate, .certificate:visited {
    color: #006aac; }
  .certificate .certificate__tooltip {
    position: absolute;
    right: 100%;
    bottom: -1.1rem;
    bottom: initial;
    bottom: 0;
    left: initial;
    display: none;
    padding: 8px 0;
    font-size: 0.9rem;
    line-height: 1.4rem;
    text-align: center;
    white-space: nowrap; }
  .certificate:hover {
    color: #00446f; }
    .certificate:hover .certificate__tooltip {
      display: block; }

.competence--expanded .certificate .certificate__tooltip {
  display: block; }
