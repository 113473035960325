/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.competences-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;
  box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 20px;
  background: white; }
  .competences-list__competence-group {
    display: flex;
    flex-direction: column; }
    .competences-list__competence-group:last-child,
    .competences-list__competence-group:last-child .competences-list__competence-group.competence:last-child {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit; }
  .competences-list__empty-state {
    color: #999;
    text-align: center; }
  .competences-list__competence-group-header {
    margin: 20px 15px;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: 1.5rem; }
  @media screen and (max-width: 768px) {
    .competences-list {
      border-radius: 8px; } }

.competence {
  display: flex;
  flex-direction: column;
  margin: 0 -20px;
  border-bottom: inset 1px #e9e9e9;
  padding: 8px 40px; }
  .competence:last-child {
    border-bottom: none; }
  .competence:hover {
    background: #f7f7f7;
    cursor: pointer; }
  .competence__info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center; }
  .competence--expanded {
    box-shadow: 0 0 2px 1px rgba(100, 100, 100, 0.2);
    margin-right: -22px;
    margin-bottom: 6px;
    margin-left: -22px;
    border-top: 1px #d8d8d8;
    border-bottom: inset 1px #d8d8d8;
    padding-right: 42px;
    padding-left: 42px;
    background: #f7f7f7; }
    .competence--expanded .competence__info {
      margin-bottom: 5px; }
  .competence__header {
    display: flex;
    align-items: center; }
  .competence__name {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5; }
  .competence__meta {
    display: flex;
    line-height: 1.5; }
  .competence__progress {
    margin-right: 10px;
    font-weight: 700;
    font-size: 0.8rem; }
  .competence__grade {
    margin-right: 10px;
    font-weight: 700;
    font-size: 0.8rem; }
  .competence__date {
    font-size: 0.8rem; }
  .competence__collapse {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 10px;
    background: none;
    color: #4c4c4c;
    font-size: 16px;
    cursor: pointer; }
  .competence__description {
    margin-top: 10px;
    line-height: 1.2; }
  @media only screen and (max-width: 768px) {
    .competence {
      padding: 6px 20px; } }
  @media only screen and (max-width: 480px) {
    .competence__collapse {
      padding-right: 0; } }
