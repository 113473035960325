/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.course-player {
  display: flex;
  width: 100%;
  height: 100%; }
  .course-player .lms-dialog {
    flex: 1 1 auto;
    max-width: 700px;
    margin: auto;
    margin-top: 0;
    border-radius: 8px;
    padding: 40px;
    background: white; }
    .course-player .lms-dialog--opened {
      max-width: 400px;
      text-align: center; }
    .course-player .lms-dialog button {
      margin-top: 20px; }
  .course-player iframe {
    flex: 0 0;
    width: 90%;
    height: 90%;
    margin: 0;
    padding: 0; }
