/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.login-page__container {
  width: 100%;
  height: 100%;
  background: white !important;
  font-family: "PT Sans Narrow", sans-serif; }
  .login-page__container .grid-x {
    height: 100%; }
  .login-page__container .img {
    position: relative;
    background-position: right top;
    background-size: cover; }
  .login-page__container .red-warning {
    padding-left: 10px;
    color: red;
    font-weight: 900; }
  .login-page__container .dark {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    background-color: black; }
  .login-page__container .faq {
    margin-top: 30px;
    color: #666;
    font-size: 1rem;
    text-align: center;
    cursor: pointer; }
    .login-page__container .faq button:hover {
      text-decoration: underline; }
  .login-page__container .login_box {
    width: 100%;
    margin: 0 auto;
    margin-top: 18%;
    font-size: 1rem;
    text-align: center; }
    @media (min-width: 768px) {
      .login-page__container .login_box {
        width: 500px; } }
    .login-page__container .login_box .logo {
      width: 180px;
      margin-bottom: 20px;
      text-align: center; }
    .login-page__container .login_box .text {
      font-size: 1.2rem;
      text-align: left; }
  .login-page__container button.back {
    color: #25a9e1; }

.login-page.app-wrapper__form-wrapper {
  overflow-y: auto;
  display: block;
  flex: 0 1 auto;
  width: 90%;
  max-width: 350px;
  box-shadow: 1px 1px 4px rgba(100, 100, 100, 0.4);
  border-radius: 10px;
  background: white; }

.login-page__title {
  margin: 17px; }

.login-page__form {
  margin: 10px;
  margin-bottom: 17px; }
