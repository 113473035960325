/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.person-competence-form {
  display: flex;
  flex-direction: column; }
  .person-competence-form__category-button {
    width: 100%;
    height: 100%;
    padding: 8px;
    padding-left: 15px;
    text-align: left;
    cursor: pointer; }
  .person-competence-form__input-wrapper {
    width: 100%; }
  .person-competence-form__section-wrapper {
    position: relative;
    overflow-y: auto;
    flex: 1 1 auto;
    width: 100%;
    min-height: 80px;
    max-height: 60vh;
    margin: 0;
    padding-right: 20px; }
  .person-competence-form__section {
    margin-top: 10px;
    border: 1px solid #efefef;
    border-radius: 5px;
    font-weight: 600;
    font-size: 1rem; }
    .person-competence-form__section.active {
      background-color: #efefef; }
      .person-competence-form__section.active .person-competence-form__category-button {
        font-weight: 900; }
  .person-competence-form__input {
    width: 100%; }
  .person-competence-form__submit-button-wrapper {
    display: block;
    flex: 0 1 auto;
    width: 100%;
    margin-top: 20px;
    padding-right: 5px;
    text-align: center; }
