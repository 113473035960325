/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.employees-report {
  font-family: "Quattrocento Sans", Helvetica, Arial, Sans-Serif; }
  .employees-report__buttons {
    margin-top: 20px;
    margin-bottom: 30px;
    padding-left: 10px;
    color: #000;
    font-size: 0.9rem;
    line-height: 1.2em;
    text-transform: uppercase; }
    .employees-report__buttons a {
      color: var(--color-main-dark); }
      .employees-report__buttons a:active, .employees-report__buttons a:visited, .employees-report__buttons a:hover {
        color: var(--color-main-dark); }
      .employees-report__buttons a:hover {
        text-decoration: underline; }
    .employees-report__buttons i {
      color: #000;
      font-size: 1.2em !important; }
  .employees-report .courseReport {
    margin-right: 50px;
    padding-right: 50px; }
    .employees-report .courseReport #ts_tbl {
      margin-bottom: 30px;
      margin-left: 10px;
      border-spacing: 0;
      border-collapse: collapse; }
      .employees-report .courseReport #ts_tbl th {
        position: relative;
        overflow: hidden;
        width: 25px;
        height: 300px;
        padding-bottom: 10px;
        vertical-align: bottom; }
      .employees-report .courseReport #ts_tbl td {
        text-align: center; }
        .employees-report .courseReport #ts_tbl td h3 {
          font-weight: 800;
          font-size: 1.3em; }
      .employees-report .courseReport #ts_tbl .col_name {
        width: 300px;
        min-width: 200px;
        max-width: 200px;
        height: 20px;
        padding: 0 5px 0 5px;
        padding: 4px;
        background: #eeebe6;
        font-size: 0.9em;
        text-align: left; }
      .employees-report .courseReport #ts_tbl .col_position {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        height: 20px;
        padding: 0 5px 0 5px;
        background: #eeebe6;
        font-weight: normal;
        font-size: 0.9em;
        text-align: left; }
      .employees-report .courseReport #ts_tbl td.course {
        width: 40px;
        padding: 3px;
        font-size: 12px; }
      .employees-report .courseReport #ts_tbl .legendcell {
        font-size: 12px;
        text-align: left; }
    .employees-report .courseReport .box_rotate {
      font-weight: bold; }
    .employees-report .courseReport .box_rotate_col {
      overflow: visible;
      display: block;
      width: 30px;
      padding-top: 0;
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      white-space: nowrap;
      -moz-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg); }
    .employees-report .courseReport .mandatory {
      background-color: #dc4e41; }
    .employees-report .courseReport .mandatory.ok {
      background-color: #5cb85c; }
    .employees-report .courseReport .mandatory.not_due {
      background-color: yellow; }
    .employees-report .courseReport .voluntary {
      border: 1px solid black;
      color: transparent; }
    .employees-report .courseReport .legendcell .buttonset {
      float: left;
      clear: both;
      width: 100%;
      margin: 3px 0; }
    .employees-report .courseReport .legendcell .buttonset label {
      width: auto; }
    .employees-report .courseReport .legendcell .line {
      width: 90%;
      margin: 2%;
      margin-left: 2%;
      margin-left: 4%;
      border-bottom: 1px solid #cecece;
      padding: 3%;
      padding-bottom: 10px; }
    .employees-report .courseReport .legendcell .line .label {
      width: 210px;
      padding-top: 2px; }
    .employees-report .courseReport .legendcell .mandatory,
    .employees-report .courseReport #coursereport .legendcell .voluntary {
      float: right;
      width: 24px;
      height: 24px;
      border: 1px solid #909090;
      padding: 2px; }
  .employees-report #coursematrix {
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;
    margin: 10px 0;
    padding-bottom: 39px; }
