/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.profile-base-info {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 10px;
  margin-bottom: 40px;
  border-radius: 10px;
  padding: 30px;
  background: #F6F6F6; }
  .profile-base-info a {
    color: inherit; }
  .profile-base-info.small {
    padding-bottom: 80px; }
  .profile-base-info__top-section {
    display: flex;
    margin-bottom: 10px; }
  .profile-base-info.cv {
    text-align: center; }
    .profile-base-info.cv a {
      color: black;
      font-weight: 600; }
  .profile-base-info .subtitle {
    margin-right: 30px;
    font-size: 0.9rem; }
  .profile-base-info .bottom-task-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top: 1px solid #ddd;
    padding-right: 20px;
    background-color: #f5f5f5;
    color: #666;
    font-size: 0.8rem;
    line-height: 40px;
    text-align: right; }
  .profile-base-info__avatar-wrapper {
    overflow: visible;
    display: flex;
    flex: 0 0 90px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-right: 20px;
    text-align: center;
    cursor: pointer; }
  .profile-base-info__avatar-loading {
    display: flex;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #e9e9e9; }
  .profile-base-info__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border: none;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-main-dark);
    font-size: 80px; }
  .profile-base-info__name {
    margin-bottom: 1em;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1;
    white-space: nowrap; }
  .profile-base-info__education-and-focus-area-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: wrap; }
    .profile-base-info__education-and-focus-area-wrapper .progress__bar-overall {
      width: 100%;
      max-width: 300px; }
  .profile-base-info__education-status-container, .profile-base-info__focus-area-container {
    display: flex;
    flex: 1 1 200px;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 40px; }
    .profile-base-info__education-status-container .progress, .profile-base-info__focus-area-container .progress {
      font-size: 1.1rem; }
      .profile-base-info__education-status-container .progress__bar-complete, .profile-base-info__focus-area-container .progress__bar-complete {
        background-color: #e12b5f; }
  .profile-base-info__education-progresses, .profile-base-info__focus-areas {
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center; }
  .profile-base-info__title {
    margin-top: 0.6em;
    color: #999;
    font-weight: 700;
    font-size: 1rem; }
  .profile-base-info__bottom-section {
    display: flex;
    flex-direction: column;
    margin-top: 10px; }
  .profile-base-info__positions {
    display: block; }
    .profile-base-info__positions:last-child {
      border-bottom: none; }
    .profile-base-info__positions .heading {
      display: flex;
      flex-direction: row;
      margin-left: 10px; }
      .profile-base-info__positions .heading h2 {
        font-weight: 400; }
      @media screen and (max-width: 480px) {
        .profile-base-info__positions .heading {
          flex-direction: column; }
          .profile-base-info__positions .heading ul.tabs {
            margin-top: -30px; } }
    .profile-base-info__positions .profile-base-info {
      padding-bottom: 80px; }
    .profile-base-info__positions .allok {
      padding-bottom: 20px;
      color: #aaa;
      text-align: center; }
      .profile-base-info__positions .allok i {
        font-size: 36px; }
      .profile-base-info__positions .allok h2 {
        color: #aaa;
        font-weight: 400;
        font-size: 18px; }
    .profile-base-info__positions .section {
      margin-bottom: 30px; }
    .profile-base-info__positions .section-position {
      margin-top: 10px;
      margin-bottom: 30px;
      border-bottom: 1px solid #e9e9e9;
      padding-bottom: 30px; }
      .profile-base-info__positions .section-position:last-child {
        border-bottom: none;
        padding-bottom: 0; }
        @media (min-width: 576px) {
          .profile-base-info__positions .section-position:last-child {
            margin-bottom: 0; } }
  .profile-base-info__position-title {
    display: flex;
    color: black;
    font-weight: 700;
    font-size: 1.5rem; }
  .profile-base-info__position-subtitle {
    margin-right: 40px;
    color: #000;
    font-size: 0.9rem; }
  .profile-base-info__position-competences-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px; }
  .profile-base-info__position-competences-group-title {
    display: flex;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5; }
  .profile-base-info__position-competence {
    display: flex;
    margin: 3px;
    color: darkgrey;
    font-size: 1rem;
    line-height: 1.5; }
  .profile-base-info__competencelevel {
    display: block;
    margin-top: 4px; }
    .profile-base-info__competencelevel .progress {
      width: 90%; }
      .profile-base-info__competencelevel .progress__bar-overall {
        width: 100%; }
  @media only screen and (max-width: 640px) {
    .profile-base-info__position .progress {
      width: 100%; }
    .profile-base-info__education-status-container {
      margin-right: 0; } }
  @media only screen and (max-width: 480px) {
    .profile-base-info__top-section {
      flex-direction: column; }
    .profile-base-info__avatar-wrapper {
      margin: 0 auto;
      margin-bottom: 25px; }
    .profile-base-info__education-status-container {
      margin: 0 auto; } }
  .profile-base-info__loaders {
    width: 100%;
    max-width: 100%;
    margin: 10px; }
    .profile-base-info__loaders__title {
      width: 150px;
      height: 16px;
      background: #f2f2f2; }
    .profile-base-info__loaders--courses {
      width: 100%;
      height: 75px;
      box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
      margin: 10px 10px 34px 65px;
      border-radius: 5px;
      padding: 10px;
      background: #fff; }
      .profile-base-info__loaders--courses .profile-base-info__loaders__text {
        width: 200px;
        height: 14px;
        margin-top: 5px;
        background: #f2f2f2; }
        .profile-base-info__loaders--courses .profile-base-info__loaders__text--short {
          width: 100px; }
    .profile-base-info__loaders--competences {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .profile-base-info__loaders--competences .profile-base-info__loaders__title {
        padding-bottom: 20px; }
      .profile-base-info__loaders--competences .profile-base-info {
        width: 100%;
        height: 170px;
        margin: 0; }
        .profile-base-info__loaders--competences .profile-base-info--small {
          width: calc(50% - 20px);
          margin-top: 40px; }
