/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.statistic-card {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
  margin: 15px;
  border-radius: 5px;
  padding: 15px 8px;
  background: #fff; }
  .statistic-card-clickable:hover {
    background: #fcfcfc; }
  .statistic-card-mobile {
    flex: 1 1 100%; }
  .statistic-card__circle-wrapper {
    position: relative;
    display: flex;
    align-self: stretch;
    width: 100px;
    height: 100px;
    margin: auto;
    margin-bottom: 0; }
    .statistic-card__circle-wrapper svg {
      width: 100%;
      height: 100%; }
  .statistic-card__circle-label {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #7f7f7f;
    font-weight: 700;
    font-size: 0.9rem; }
  .statistic-card__title {
    margin: auto;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center; }
