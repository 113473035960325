/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.badge-card {
  display: flex;
  align-items: center;
  min-height: 100px;
  box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
  margin: 10px 0;
  border: 1px solid #ccc;
  padding: 10px 25px;
  background: #fff; }
  .badge-card__icon {
    overflow: visible;
    display: flex;
    flex: 0 0 65px;
    justify-content: center;
    align-items: center;
    width: 65px;
    min-width: 65px;
    height: 65px;
    border-radius: 100%; }
    .badge-card__icon__text {
      font-weight: 800;
      font-size: 20px; }
  .badge-card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 25px;
    color: #6a6868; }
  .badge-card__title {
    font-weight: bold;
    font-size: 1.2rem; }
  .badge-card__text {
    font-size: 1rem; }
