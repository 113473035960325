/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.message-form {
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  .message-form__buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 7px; }
  .message-form__button {
    display: flex;
    margin: 10px 0 20px 10px;
    text-align: center;
    vertical-align: middle; }
    .message-form__button:hover {
      box-shadow: none; }
  .message-form__input-wrapper {
    margin: 0 10px;
    margin-bottom: 20px; }
  .message-form__submit-button {
    margin: 10px;
    margin-bottom: 20px; }
