/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.my-education-switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  padding: 0 20px; }
  .my-education-switch__section {
    width: 100%;
    margin: 15px 0 0;
    transition: flex 0.3s ease-out; }
  .my-education-switch__section-option {
    margin: 0;
    padding: 0; }
  @media screen and (max-width: 768px) {
    .my-education-switch__section {
      width: 100%;
      margin: 5px 0 0; } }
