/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.competence-groups-sidebar {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  margin-right: 0;
  margin-top: 36px; }
  .competence-groups-sidebar__courses, .competence-groups-sidebar__filters {
    display: flex;
    flex-direction: column; }
  .competence-groups-sidebar__tabs {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px; }
    .competence-groups-sidebar__tabs__tab {
      flex: 0 0 auto;
      padding: 7px 18px;
      background: #d8d8d8;
      font-size: 0.9rem;
      cursor: pointer; }
      .competence-groups-sidebar__tabs__tab:hover {
        border: 1px solid var(--color-main-dark);
        padding: 6px 17px; }
      .competence-groups-sidebar__tabs__tab.active {
        background: var(--color-main-dark);
        color: #fff; }
  .competence-groups-sidebar__item-category {
    margin-bottom: 3px;
    font-weight: 600; }
  .competence-groups-sidebar__divider {
    margin-top: 20px;
    border-top: 1px solid #ccc; }
  .competence-groups-sidebar__subtitle {
    color: #6e6e6e;
    font-size: 1rem; }
  .competence-groups-sidebar__item {
    color: #6e6e6e;
    font-size: 0.9rem;
    cursor: pointer;
    font-weight: 400;
    padding: 10px;
    padding-left: 20px; }
    .competence-groups-sidebar__item input[type='checkbox'],
    .competence-groups-sidebar__item input[type='radio'] {
      display: none; }
    .competence-groups-sidebar__item.active {
      background-color: #eeeeee; }
    .competence-groups-sidebar__item .dott {
      width: 10px; }
    .competence-groups-sidebar__item .label {
      display: inline-block;
      width: 80%;
      line-height: 120%; }
    .competence-groups-sidebar__item input[type='checkbox'] + div {
      position: relative;
      top: -0.1em;
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 4px;
      border: 1px solid #666;
      background: #fff;
      cursor: pointer;
      vertical-align: middle; }
    .competence-groups-sidebar__item input[type='radio']:checked + div {
      background: var(--color-main-dark); }
    .competence-groups-sidebar__item input[type='checkbox']:checked + div {
      background: var(--color-main-dark); }
  .competence-groups-sidebar__buttons-disabled .competence-groups-sidebar__item {
    opacity: 0.6;
    cursor: default; }
    .competence-groups-sidebar__buttons-disabled .competence-groups-sidebar__item .dott {
      cursor: default; }
  .competence-groups-sidebar__buttons-disabled .competence-groups-sidebar__subtitle {
    color: #999; }
  .competence-groups-sidebar--mobile {
    margin-left: 7px;
    margin-right: 20px;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    border-right: none; }
    .competence-groups-sidebar--mobile .courses-menu {
      flex: 0 0;
      align-items: center;
      width: auto; }
      .competence-groups-sidebar--mobile .courses-menu__sorting-wrapper {
        margin: 0; }
      .competence-groups-sidebar--mobile .courses-menu__buttons-wrapper {
        height: 100%;
        margin: 0; }
      .competence-groups-sidebar--mobile .courses-menu__sorting-wrapper {
        height: 100%; }
      .competence-groups-sidebar--mobile .courses-menu .sorting-active {
        align-items: center; }
        .competence-groups-sidebar--mobile .courses-menu .sorting-active .sorting-options {
          margin-top: -1px; }
    .competence-groups-sidebar--mobile .competence-groups-sidebar__tabs {
      display: block;
      flex: 0 0 auto;
      margin-bottom: 20px;
      margin-left: 20px; }
      .competence-groups-sidebar--mobile .competence-groups-sidebar__tabs__tab {
        float: left;
        margin-right: 15px;
        padding: 6px 6px;
        background: transparent;
        color: #4c4c4c; }
        .competence-groups-sidebar--mobile .competence-groups-sidebar__tabs__tab:hover {
          border: 0;
          border-bottom: 1px solid #000;
          padding-bottom: 5px; }
        .competence-groups-sidebar--mobile .competence-groups-sidebar__tabs__tab.active {
          border: none;
          border-bottom: 2px solid #000;
          padding-bottom: 4px;
          background: transparent;
          color: #000;
          font-weight: 500; }
    .competence-groups-sidebar--mobile .competence-groups-sidebar__buttons {
      display: flex;
      flex: 0 0 auto;
      flex-direction: row;
      margin-top: -6px;
      margin-right: 31px;
      margin-bottom: 22px;
      margin-left: 25px; }
    .competence-groups-sidebar--mobile .competence-groups-sidebar__item {
      width: 50%;
      min-width: 150px;
      font-weight: 400; }
    .competence-groups-sidebar--mobile .competence-groups-sidebar__divider {
      width: 100%;
      margin-top: 10px; }
    .competence-groups-sidebar--mobile .competence-groups-sidebar__filters-toggle {
      z-index: 2;
      flex: 0 0;
      width: 133px;
      height: 100%;
      border: 1px solid transparent;
      padding: 0 20px;
      color: #4c4c4c;
      font-size: 0.9rem;
      white-space: nowrap;
      cursor: pointer; }
      .competence-groups-sidebar--mobile .competence-groups-sidebar__filters-toggle i {
        margin-left: 10px;
        color: #666;
        font-size: 0.8rem; }
      .competence-groups-sidebar--mobile .competence-groups-sidebar__filters-toggle--active {
        border: 1px solid #b2b2b2;
        border-radius: 0;
        border-bottom: 1px solid #e5e5e5;
        background: #e5e5e5; }
    .competence-groups-sidebar--mobile .competence-groups-sidebar__filters {
      z-index: 1;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      min-width: 131px;
      margin-top: -21px;
      padding: 10px 0; }
      .competence-groups-sidebar--mobile .competence-groups-sidebar__filters--active {
        margin-bottom: 20px; }
    .competence-groups-sidebar--mobile .competence-groups-sidebar__courses {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%; }
  .competence-groups-sidebar__competences-search {
    width: 100%;
    padding: 0;
    padding-right: 20px;
    margin-bottom: 30px; }
    .competence-groups-sidebar__competences-search .search-bar__label {
      box-shadow: none;
      border: 1px solid #c8c8c8; }
    .competence-groups-sidebar__competences-search .search-bar__input {
      padding: 5px 10px;
      font-size: 0.9rem; }
  .competence-groups-sidebar__clear-search {
    margin-top: 10px;
    color: #6e6e6e;
    font-weight: bold;
    font-size: 0.9rem;
    text-align: left; }
