/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.signature-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  height: auto;
  margin: auto;
  border-radius: 8px;
  padding: 15px;
  background: white; }
  .signature-modal__header {
    margin: 5px 0;
    padding-bottom: 20px; }
  .signature-modal__input-wrapper {
    width: 100%; }
  .signature-modal__input {
    width: 100%; }
  .signature-modal__submit-button-wrapper {
    display: block;
    width: 100%;
    margin-top: 40px;
    padding-right: 5px;
    text-align: right; }
