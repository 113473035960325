/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.competence-report {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1600px;
  margin: 0 auto;
  margin-bottom: 100px;
  padding: 40px 80px; }
  @media screen and (max-width: 1100px) {
    .competence-report {
      padding: 40px 20px; } }
  @media screen and (max-width: 850px) {
    .competence-report {
      padding: 40px 10px; } }
  .competence-report__header {
    margin: 0;
    margin-bottom: 20px;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: 40px; }
  .competence-report__top-section {
    position: relative;
    display: flex;
    flex: 1 1 100%;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 40px; }
    .competence-report__top-section__chart {
      position: relative;
      overflow: visible;
      flex: 1 1 auto;
      height: 280px;
      margin-right: 40px;
      margin-left: -80px; }
      @media screen and (max-width: 1100px) {
        .competence-report__top-section__chart {
          margin-right: 10px;
          margin-left: -40px; } }
      .competence-report__top-section__chart .extra-chart {
        overflow: visible;
        height: 100%; }
    .competence-report__top-section__statistics {
      flex: 0 0 40%; }
      .competence-report__top-section__statistics > * {
        margin-top: 10px;
        margin-bottom: 30px; }
    @media screen and (max-width: 850px) {
      .competence-report__top-section {
        flex-direction: column; }
        .competence-report__top-section__chart {
          height: 200px;
          margin: 0;
          margin-bottom: 30px; } }
  .competence-report__section-header {
    flex: 0 0 auto;
    align-self: baseline;
    margin-top: 20px;
    margin-right: auto;
    margin-bottom: 20px;
    border-bottom: 3px solid #618da7;
    padding: 5px 8px;
    color: #6a6868;
    font-weight: 700;
    font-size: 2rem; }
    .competence-report__section-header-wrapper {
      display: flex;
      flex: 1 1 auto;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin-right: 0;
      margin-left: auto; }
  .competence-report__main {
    display: flex;
    flex-direction: column;
    box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
    margin-top: 40px;
    margin-bottom: 60px; }
    .competence-report__main__title {
      overflow: hidden;
      flex: 1 1 100%;
      width: 100%;
      border: 1px solid #ccc;
      border-bottom: none;
      padding: 20px 40px;
      background: #fff;
      color: #666;
      font-weight: bold;
      font-size: 1.2rem;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .competence-report__main__content {
      display: flex;
      flex: 1 1 100%;
      flex-direction: row; }
      @media screen and (max-width: 850px) {
        .competence-report__main__content {
          flex-direction: column; } }
    .competence-report__main__aside {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border: 1px solid #ccc;
      background: #fff; }
      .competence-report__main__aside__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50%;
        border-bottom: 1px solid #ccc;
        padding: 0 20px; }
      .competence-report__main__aside :last-child {
        border-bottom: none; }
      @media screen and (max-width: 850px) {
        .competence-report__main__aside {
          position: relative;
          flex-direction: row;
          width: 100%;
          height: auto;
          border-top: none; }
          .competence-report__main__aside :last-child {
            border-right: none; }
          .competence-report__main__aside__item {
            height: 100%;
            margin: 0;
            border: none;
            border-right: 1px solid #ccc;
            padding: 10px 0; } }
  .competence-report__chart {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 50px; }
    .competence-report__chart-container {
      position: relative;
      display: block;
      width: 100%;
      height: 450px;
      border: 1px solid #ccc;
      border-right: none;
      background: #fff; }
    @media screen and (max-width: 850px) {
      .competence-report__chart {
        margin: 20px; }
        .competence-report__chart-container {
          height: 400px;
          border-right: 1px solid #ccc; } }
  .competence-report__badges {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -20px;
    margin-bottom: 60px; }
    @media screen and (max-width: 1100px) {
      .competence-report__badges {
        margin-right: -10px;
        margin-left: -10px; } }
    @media screen and (max-width: 850px) {
      .competence-report__badges {
        flex-direction: column;
        flex-wrap: unset; } }
    .competence-report__badges__column {
      display: flex;
      flex: 0 1 50%;
      flex-direction: column; }
  .competence-report__badge {
    overflow: hidden;
    flex: 1 1 auto;
    height: 90px;
    padding: 0 25px; }
    .competence-report__badge-wrapper {
      display: flex;
      flex: 0 0 auto;
      margin: 6px 0;
      padding: 0 20px; }
    @media screen and (max-width: 1100px) {
      .competence-report__badge-wrapper {
        padding: 0 10px; } }
    @media screen and (max-width: 850px) {
      .competence-report__badge {
        margin: 5px 0; } }
  @media screen and (max-width: 640px) {
    .competence-report__badge {
      width: 100%; } }
