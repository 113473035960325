/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.mobile-report {
  display: flex;
  flex-direction: column; }
  .mobile-report__main-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    padding: 0 7px; }
  .mobile-report__select {
    flex-shrink: 1;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0; }
    .mobile-report__select-button {
      display: flex;
      justify-content: space-between;
      font-size: 1.2rem; }
  .mobile-report__side {
    flex: 0 0;
    width: 60px;
    height: 100%;
    margin: 0;
    margin-right: 7px;
    margin-left: 7px; }
    .mobile-report__side-button {
      width: 100%;
      min-width: 0;
      font-size: 1.2rem; }
  .mobile-report__button-wrapper {
    margin: 0;
    padding: 15px 8px;
    line-height: inherit; }
  .mobile-report__options {
    position: absolute;
    overflow: hidden;
    left: 7px;
    right: 7px;
    margin: 50px 7px 5px 7px;
    border: 1px solid var(--color-main-dark);
    border-radius: 7px; }
  .mobile-report__option {
    box-shadow: none;
    border-radius: 0;
    line-height: inherit; }
    .mobile-report__option-title {
      box-shadow: 0 1px 2px 0 var(--color-main-dark);
      margin: 0;
      border-radius: 0;
      padding: 10px;
      padding-left: 14px;
      border-top: 1px solid var(--color-main-dark);
      background: #fff;
      color: var(--color-main-dark);
      font-weight: bold; }
      .mobile-report__option-title :first-child {
        border-top: none; }
    .mobile-report__option-content {
      box-shadow: 0 1px 2px 0 var(--color-main-dark);
      margin: 0;
      border-radius: 0;
      padding-left: 10px;
      background: #fff; }
      .mobile-report__option-content > div {
        color: var(--color-main-dark);
        font-weight: normal; }
