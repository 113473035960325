/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.highscore-list {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  align-self: flex-start;
  max-width: 500px;
  margin: 0 auto; }
  @media screen and (max-width: 850px) {
    .highscore-list {
      margin-bottom: 40px; } }
  .highscore-list__title {
    align-items: baseline;
    margin: 0;
    margin-bottom: 0;
    color: #6a6868;
    font-weight: 700;
    font-size: 1.5rem; }
    .highscore-list__title__wrapper {
      overflow: visible;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 1em;
      margin: 14px 0;
      margin-left: 8px;
      color: #6a6868;
      font-weight: 700;
      font-size: 1.5rem; }
  .highscore-list__content {
    flex: 1 1 auto;
    flex-direction: column;
    box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fff;
    will-change: transform; }
    .highscore-list__content__item {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      margin: 0;
      margin-left: 4px;
      font-size: 0.9rem; }
      .highscore-list__content__item__labels {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%; }
        .highscore-list__content__item__labels > * {
          overflow: hidden;
          width: 100%;
          line-height: 1.4;
          text-overflow: ellipsis;
          white-space: nowrap; }
          .highscore-list__content__item__labels > *:first-child {
            margin-top: auto; }
          .highscore-list__content__item__labels > *:last-child {
            margin-bottom: auto; }
      .highscore-list__content__item__wrapper {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #f2f2f2;
        padding: 8px 14px; }
        .highscore-list__content__item__wrapper:last-child {
          border-bottom: 0; }
      .highscore-list__content__item__avatar {
        flex: 0 0 28%;
        align-self: center;
        padding: 3px 0; }
        .highscore-list__content__item__avatar .person-avatar {
          width: 55px;
          height: 55px;
          margin: auto; }
      .highscore-list__content__item__name, .highscore-list__content__item__score {
        font-weight: 600; }
      .highscore-list__content__item__score {
        color: #618da7; }
      .highscore-list__content__item__details {
        color: #b2b2b2; }
