/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.highscore-list {
  display: flex;
  flex: 1 1 50%;
  flex-direction: column;
  align-self: flex-start; }
  .highscore-list__title {
    margin: 14px 0;
    margin-left: 8px;
    color: #6a6868;
    font-weight: 700;
    font-size: 1.5rem; }
  .highscore-list__content {
    flex: 1 1 auto;
    flex-direction: column;
    box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #fff; }
    .highscore-list__content__item {
      flex: 1 1 auto;
      margin: auto 0;
      font-size: 0.9rem;
      line-height: 1.4; }
      .highscore-list__content__item__wrapper {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #f2f2f2;
        padding: 6px 14px; }
        .highscore-list__content__item__wrapper:last-child {
          border-bottom: 0; }
      .highscore-list__content__item__avatar {
        flex: 0 0 110px;
        align-self: center; }
        .highscore-list__content__item__avatar > :first-child {
          width: 65px;
          height: 65px;
          margin: auto; }
      .highscore-list__content__item__name, .highscore-list__content__item__score {
        font-weight: 600; }
      .highscore-list__content__item__score {
        color: #618da7; }
      .highscore-list__content__item__details {
        color: #b2b2b2; }
  .highscore-list__expand-button {
    width: 30px;
    height: 30px;
    margin: 20px auto 0;
    border: 1px solid #6a6868;
    border-radius: 50%;
    background-color: #fff;
    color: #6a6868;
    font-size: 13px;
    padding-left: 2px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 3px;
    cursor: pointer; }
