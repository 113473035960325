/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.person-form {
  display: flex;
  flex-wrap: wrap; }
  .person-form__input-wrapper {
    flex: 1 0 50%; }
  .person-form__input {
    width: 100%; }
  .person-form__submit-button-wrapper {
    display: block;
    width: 100%;
    margin-top: 30px;
    padding-right: 5px;
    text-align: right; }
  @media only screen and (max-width: 768px) {
    .person-form {
      justify-content: center; }
      .person-form__input-wrapper {
        flex: 0 0 100%; }
      .person-form__submit-button-wrapper {
        width: auto; } }
