/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.form-ckeditor {
  display: flex;
  flex-direction: column;
  padding: 7px; }
  .form-ckeditor__label {
    padding: 0 4px;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 24px; }
  .form-ckeditor__editor {
    display: flex;
    border: 1px solid grey;
    border-radius: 4px; }
  .form-ckeditor__error {
    color: #c22;
    font-size: 12px;
    line-height: 18px;
    text-align: center; }
