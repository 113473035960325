/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.employee-edit {
  display: flex;
  justify-content: space-between; }
  .employee-edit__left-column {
    float: left;
    clear: both;
    width: 275px;
    min-width: 225px;
    max-width: 300px; }
  .employee-edit__right-column {
    width: 100%;
    padding-left: 310px; }
  .employee-edit__card, .employee-edit__avatar-card, .employee-edit__actions-card {
    display: flex;
    box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
    padding: 20px 20px 40px 20px;
    background: white; }
  .employee-edit__modal-content {
    overflow: visible; }
  .employee-edit__modal {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    width: 80vw;
    max-width: 800px;
    padding: 30px 40px 20px;
    border-radius: 8px;
    max-height: 80vh;
    overflow-y: auto; }
    @media (min-width: 576px) {
      .employee-edit__modal {
        max-height: 60vh; } }
    .employee-edit__modal .person-competence-form {
      overflow: auto;
      flex: 1 1 auto; }
    .employee-edit__modal-onthejob {
      flex-direction: column; }
      @media (min-width: 576px) {
        .employee-edit__modal-onthejob {
          width: 35vw;
          min-width: 440px; } }
      @media (min-width: 768px) {
        .employee-edit__modal-onthejob {
          width: 79vw;
          min-width: 440px; } }
      @media (min-width: 992px) {
        .employee-edit__modal-onthejob {
          width: 60vw;
          min-width: 440px; } }
    .employee-edit__modal-title {
      flex: 0 1 auto;
      margin-top: 0;
      margin-bottom: 5px;
      color: var(--color-main-dark);
      font-size: 1.44rem; }
      .employee-edit__modal-title--small {
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 1.08rem; }
    .employee-edit__modal-paragraph {
      margin: 10px 0;
      color: #4c4c4c;
      font-size: 0.9rem; }
    .employee-edit__modal-button-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 75px; }
    .employee-edit__modal-button {
      padding: 10px 30px; }
  .employee-edit__modal-hidden {
    display: none; }
  .employee-edit__avatar-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0 20px; }
  .employee-edit__avatar-image {
    width: 40%; }
  .employee-edit__avatar-name {
    margin-top: 5px;
    color: var(--color-main-dark);
    font-weight: 500;
    font-size: 1.2rem; }
  .employee-edit__form {
    flex: 1 1 auto; }
  .employee-edit__actions-button {
    width: 100%;
    height: 60px;
    text-align: left;
    cursor: pointer; }
    .employee-edit__actions-button i {
      margin: 0 20px 0; }
  .employee-edit__actions-card {
    display: flex;
    flex-direction: column;
    padding: 0; }
  .employee-edit__actions-item {
    width: 100%;
    border-bottom: 1px solid #ccc;
    font-size: 0.9rem; }
    .employee-edit__actions-item:last-of-type {
      border: 0; }
  .employee-edit__status-message {
    margin: 10px;
    color: var(--color-main-dark);
    font-weight: 700;
    text-align: center; }
  @media screen and (max-width: 768px) {
    .employee-edit {
      flex-direction: column; }
      .employee-edit__left-column {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        order: 2;
        width: 100%;
        min-width: inherit;
        max-width: inherit; }
      .employee-edit__right-column {
        padding-left: 0; }
      .employee-edit__avatar {
        width: 40%; }
      .employee-edit__actions {
        width: 100%; }
        .employee-edit__actions-title {
          margin-top: 0;
          text-align: center; }
      .employee-edit__left-column {
        flex-direction: column; }
      .employee-edit__actions {
        width: 100%;
        margin: 20px 0; }
      .employee-edit__avatar {
        width: 100%; }
        .employee-edit__avatar-image {
          width: 30%; }
      .employee-edit__modal {
        width: 95vw;
        min-width: unset;
        margin: 0 auto;
        padding: 15px 15px 20px;
        border-radius: 8px; }
      .employee-edit .form-input__label {
        max-width: 40%; } }
