/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.progress {
  color: #7f7f7f;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
  white-space: nowrap; }
  .progress__progress {
    display: inline-block;
    margin-right: 12px;
    line-height: 24px;
    vertical-align: middle; }
  .progress__label {
    display: inline-block;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 24px;
    vertical-align: middle; }
  .progress__bar-overall {
    max-width: 300px;
    height: 7px;
    margin-top: 2px;
    border-radius: 5px;
    background-color: #ccc; }
    .progress__bar-overall--full-width {
      width: 100%;
      max-width: 100%; }
  .progress__bar-complete {
    height: 7px;
    border-radius: 5px;
    background-color: #2f93e0; }
