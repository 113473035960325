/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.roles-list {
  display: flex;
  flex-direction: column;
  margin: 10px;
  margin-top: 0; }
  .roles-list .competence-card {
    box-shadow: none;
    position: relative;
    margin: 0;
    border-radius: 0;
    border-bottom: 1px solid #e9e9e9;
    padding: 0;
    font-size: 0.9rem; }
    .roles-list .competence-card:hover .removeThis {
      position: absolute;
      top: 10px;
      right: 10px;
      display: block;
      color: red; }
      .roles-list .competence-card:hover .removeThis * {
        cursor: pointer; }
      .roles-list .competence-card:hover .removeThis.hassub {
        top: 20px;
        right: 40px; }
    .roles-list .competence-card .removeThis {
      display: none; }
    .roles-list .competence-card.employee .button {
      margin-top: 10px;
      margin-right: 10px; }
    .roles-list .competence-card .sub h3 {
      margin-bottom: 0;
      border-bottom: 0;
      text-indent: 40px; }
    .roles-list .competence-card .sub > .competence-card {
      border: none;
      padding-left: 30px;
      background-color: #fafafa; }
  .roles-list__report-link {
    width: 100%;
    font-size: 0.9rem;
    text-align: right; }
    .roles-list__report-link a {
      color: #999; }
  .roles-list__loading {
    margin-left: 20px; }
  .roles-list__role-section {
    flex-direction: column;
    margin-bottom: 30px; }
    .roles-list__role-section__header {
      display: flex;
      flex-direction: row; }
      @media only screen and (max-width: 950px) {
        .roles-list__role-section__header {
          flex-direction: column; } }
    .roles-list__role-section h2 {
      flex: 1 1 auto;
      margin-top: 0; }
    .roles-list__role-section ul.tabs {
      float: right;
      flex: 0 0 auto;
      margin-top: 0;
      margin-right: 20px;
      margin-bottom: 20px;
      padding-left: 0;
      font-size: 1rem; }
      .roles-list__role-section ul.tabs li {
        padding-top: 0; }
  .roles-list__role, .roles-list__role__loader {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-shadow: 1px 1px 4px rgba(100, 100, 100, 0.4);
    margin-bottom: 10px;
    border-radius: 5px;
    background: white; }
    .roles-list__role:not(:last-of-type), .roles-list__role__loader:not(:last-of-type) {
      margin-bottom: 10px; }
    .roles-list__role svg, .roles-list__role__loader svg {
      flex: 0 0 auto;
      width: 500px;
      height: 42px;
      margin: auto 0;
      margin-left: 12px; }
  .roles-list__role__loader {
    overflow: hidden;
    height: 52px; }
  .roles-list__role-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
    padding: 15px;
    padding-left: 20px;
    cursor: pointer; }
    .roles-list__role-header:last-child {
      border-bottom: none; }
  .roles-list__role-expand-button {
    margin-right: 10px;
    color: #666; }
  .roles-list__role-title {
    flex: 1 1 0;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5; }
  .roles-list__role-progress {
    flex: 0 1 100px;
    margin-right: 20px; }
    .roles-list__role-progress .progress {
      font-weight: 400;
      font-size: 0.8rem; }
  .roles-list__competence {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e9e9e9;
    padding: 10px;
    padding-left: 20px; }
    .roles-list__competence:last-child {
      border-bottom: none; }
    .roles-list__competence .signed-info {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      font-size: 0.8rem; }
      .roles-list__competence .signed-info svg {
        height: auto;
        color: #999; }
      .roles-list__competence .signed-info div {
        flex: 0 0 auto;
        margin-right: 20px; }
  .roles-list__competence-status {
    flex: 0 0 auto;
    margin-right: 10px; }
    .roles-list__competence-status::after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border: 1px solid grey;
      border-radius: 50%; }
    .roles-list__competence-status--not-started::after {
      border-color: red; }
    .roles-list__competence-status--in-progress::after {
      border-color: yellow; }
    .roles-list__competence-status--completed::after {
      border-color: green; }
  .roles-list__competence-title {
    display: flex;
    flex: 0 0 auto;
    align-items: baseline;
    margin-right: 20px;
    font-size: 15px; }
  .roles-list__competence-description {
    flex: 1 1 auto;
    font-size: 13px; }
