/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.employee-card,
.employee-card__loader,
.employee-card__loader--expanded {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
  border-radius: 8px;
  padding: 20px;
  background: #fff;
  color: #191919;
  font-size: 13px;
  line-height: 1.5;
  white-space: break-word; }
  .employee-card--clickable,
  .employee-card__loader--clickable,
  .employee-card__loader--expanded--clickable {
    cursor: pointer; }
    .employee-card--clickable:hover,
    .employee-card__loader--clickable:hover,
    .employee-card__loader--expanded--clickable:hover {
      background-color: #f2f2f2; }
      .employee-card--clickable:hover .person-avatar,
      .employee-card__loader--clickable:hover .person-avatar,
      .employee-card__loader--expanded--clickable:hover .person-avatar {
        box-shadow: 1px 1px 2px rgba(100, 100, 100, 0.2); }
  .employee-card--centered,
  .employee-card__loader--centered,
  .employee-card__loader--expanded--centered {
    align-items: center; }
  .employee-card--expanded,
  .employee-card__loader--expanded,
  .employee-card__loader--expanded--expanded {
    align-items: center;
    background: white;
    color: inherit;
    font-size: 14px; }
    .employee-card--expanded__top-section,
    .employee-card__loader--expanded__top-section,
    .employee-card__loader--expanded--expanded__top-section {
      margin-bottom: 10px; }
    .employee-card--expanded .employee-card__name,
    .employee-card__loader--expanded .employee-card__name,
    .employee-card__loader--expanded--expanded .employee-card__name {
      margin-bottom: 4px;
      line-height: 1.3;
      text-align: center; }
  .employee-card__top-section,
  .employee-card__loader__top-section,
  .employee-card__loader--expanded__top-section {
    display: flex;
    flex: 1 0 0; }
    .employee-card__top-section--vertical,
    .employee-card__loader__top-section--vertical,
    .employee-card__loader--expanded__top-section--vertical {
      flex-direction: column;
      align-items: center;
      margin-bottom: 0; }
  .employee-card__avatar-wrapper,
  .employee-card__loader__avatar-wrapper,
  .employee-card__loader--expanded__avatar-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; }
  .employee-card__chart,
  .employee-card__loader__chart,
  .employee-card__loader--expanded__chart {
    position: absolute; }
  .employee-card__avatar,
  .employee-card__loader__avatar,
  .employee-card__loader--expanded__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    padding: 0;
    background-color: transparent;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: var(--color-main-dark);
    font-size: 50px; }
    .employee-card__avatar--centered,
    .employee-card__loader__avatar--centered,
    .employee-card__loader--expanded__avatar--centered {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      color: var(--color-main-dark);
      font-size: 100px; }
    .employee-card__avatar i,
    .employee-card__loader__avatar i,
    .employee-card__loader--expanded__avatar i {
      transform: scale(1.1); }
  .employee-card__name-wrapper,
  .employee-card__loader__name-wrapper,
  .employee-card__loader--expanded__name-wrapper {
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
    color: #191919;
    text-overflow: ellipsis;
    white-space: normal; }
    .employee-card__name-wrapper--centered,
    .employee-card__loader__name-wrapper--centered,
    .employee-card__loader--expanded__name-wrapper--centered {
      align-items: center;
      margin-top: 15px;
      margin-left: 0;
      color: var(--color-main-dark); }
  .employee-card__name,
  .employee-card__loader__name,
  .employee-card__loader--expanded__name {
    overflow-wrap: break-word;
    font-weight: 700;
    font-size: 1.2rem;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal;
    hyphens: auto; }
  .employee-card__position,
  .employee-card__loader__position,
  .employee-card__loader--expanded__position {
    overflow-wrap: break-word;
    font-weight: 700;
    font-size: 0.9rem;
    text-overflow: ellipsis;
    white-space: normal;
    hyphens: auto; }
  .employee-card__phone,
  .employee-card__loader__phone,
  .employee-card__loader--expanded__phone {
    overflow-wrap: break-word;
    font-size: inherit;
    text-overflow: ellipsis;
    white-space: normal;
    hyphens: auto; }
    .employee-card__phone a,
    .employee-card__loader__phone a,
    .employee-card__loader--expanded__phone a {
      color: #000;
      text-decoration: none; }
  .employee-card__email,
  .employee-card__loader__email,
  .employee-card__loader--expanded__email {
    overflow-wrap: break-word;
    font-size: inherit;
    text-overflow: ellipsis;
    white-space: normal;
    hyphens: auto; }
    .employee-card__email a,
    .employee-card__loader__email a,
    .employee-card__loader--expanded__email a {
      color: #000;
      text-decoration: none; }
  .employee-card__progress-wrapper,
  .employee-card__loader__progress-wrapper,
  .employee-card__loader--expanded__progress-wrapper {
    width: 100%;
    margin: 20px 0;
    margin-bottom: 13px;
    padding: 0 15px; }
    .employee-card__progress-wrapper .progress__bar-complete,
    .employee-card__loader__progress-wrapper .progress__bar-complete,
    .employee-card__loader--expanded__progress-wrapper .progress__bar-complete {
      height: 7px;
      border-radius: 3px;
      background-color: #e12b5f; }
  .employee-card__actions,
  .employee-card__loader__actions,
  .employee-card__loader--expanded__actions {
    display: flex;
    justify-content: space-around;
    width: 140px;
    margin-top: 7px; }
  .employee-card__button,
  .employee-card__loader__button,
  .employee-card__loader--expanded__button {
    width: 45px;
    height: 45px;
    box-shadow: 1px 1px 4px rgba(100, 100, 100, 0.4);
    border: none;
    border-radius: 30px;
    background: e;
    color: #fff;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    cursor: pointer; }
  @media only screen and (max-width: 768px) {
    .employee-card,
    .employee-card__loader,
    .employee-card__loader--expanded {
      padding: 10px; } }
  .employee-card--mobile,
  .employee-card__loader--mobile,
  .employee-card__loader--expanded--mobile {
    float: none !important;
    flex: 1 1 auto;
    flex-direction: row;
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
    min-height: 0 !important;
    box-shadow: none;
    background: none; }
    .employee-card--mobile .employee-card__name,
    .employee-card__loader--mobile .employee-card__name,
    .employee-card__loader--expanded--mobile .employee-card__name {
      margin-bottom: 8px;
      font-size: 1rem; }
    .employee-card--mobile .employee-card__progress-wrapper,
    .employee-card__loader--mobile .employee-card__progress-wrapper,
    .employee-card__loader--expanded--mobile .employee-card__progress-wrapper {
      margin: 0;
      padding: 0; }
    .employee-card--mobile .employee-card__actions,
    .employee-card__loader--mobile .employee-card__actions,
    .employee-card__loader--expanded--mobile .employee-card__actions {
      position: relative;
      justify-content: normal !important;
      width: auto;
      margin-bottom: 5px; }
      .employee-card--mobile .employee-card__actions .btn--icon,
      .employee-card__loader--mobile .employee-card__actions .btn--icon,
      .employee-card__loader--expanded--mobile .employee-card__actions .btn--icon {
        margin-top: auto;
        margin-left: 10px; }
      .employee-card--mobile .employee-card__actions .btn--expanded,
      .employee-card__loader--mobile .employee-card__actions .btn--expanded,
      .employee-card__loader--expanded--mobile .employee-card__actions .btn--expanded {
        border-radius: 0; }
      .employee-card--mobile .employee-card__actions .btn__dropdown,
      .employee-card__loader--mobile .employee-card__actions .btn__dropdown,
      .employee-card__loader--expanded--mobile .employee-card__actions .btn__dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 10;
        width: 250px; }
        .employee-card--mobile .employee-card__actions .btn__dropdown .task-list,
        .employee-card__loader--mobile .employee-card__actions .btn__dropdown .task-list,
        .employee-card__loader--expanded--mobile .employee-card__actions .btn__dropdown .task-list {
          box-shadow: none; }
          .employee-card--mobile .employee-card__actions .btn__dropdown .task-list__item,
          .employee-card__loader--mobile .employee-card__actions .btn__dropdown .task-list__item,
          .employee-card__loader--expanded--mobile .employee-card__actions .btn__dropdown .task-list__item {
            border-radius: 0 !important;
            border-bottom: none;
            background: var(--color-main-dark); }
            .employee-card--mobile .employee-card__actions .btn__dropdown .task-list__item .employee-preview__add-menu-item,
            .employee-card__loader--mobile .employee-card__actions .btn__dropdown .task-list__item .employee-preview__add-menu-item,
            .employee-card__loader--expanded--mobile .employee-card__actions .btn__dropdown .task-list__item .employee-preview__add-menu-item {
              border-bottom: 1px solid #fff;
              background-color: transparent !important;
              color: #fff; }
            .employee-card--mobile .employee-card__actions .btn__dropdown .task-list__item:last-child .employee-preview__add-menu-item,
            .employee-card__loader--mobile .employee-card__actions .btn__dropdown .task-list__item:last-child .employee-preview__add-menu-item,
            .employee-card__loader--expanded--mobile .employee-card__actions .btn__dropdown .task-list__item:last-child .employee-preview__add-menu-item {
              border-bottom: none; }

.employee-card__loader {
  height: 90px; }
  .employee-card__loader svg {
    flex: 0 0 auto;
    width: 225px;
    height: 50px; }
  .employee-card__loader--expanded {
    display: flex;
    align-items: flex-start;
    height: 375px;
    padding: 0; }
    .employee-card__loader--expanded svg {
      flex: 0 0 auto;
      width: 225px;
      height: 306px;
      margin: 0 auto;
      margin-top: 0; }
  .employee-card__loader--mobile .employee-card__top-section {
    overflow: hidden;
    flex: 1 1 auto;
    width: 409px; }
    .employee-card__loader--mobile .employee-card__top-section svg {
      flex: 0 0 auto;
      width: 409px;
      height: 52px; }
  .employee-card__loader--mobile .employee-card__actions svg {
    flex: 0 0 auto;
    width: 150px;
    height: 40px; }
