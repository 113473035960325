/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  max-width: 1280px;
  margin: 0 auto; }
  .profile-page__section {
    width: 100%;
    margin: 15px 0 0; }
  .profile-page__card {
    display: flex;
    flex-wrap: wrap;
    box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
    margin: 10px;
    border-radius: 8px;
    padding: 20px 20px 40px 20px;
    background: white; }
  .profile-page__profile-picture-wrapper {
    min-width: 100px;
    margin: 0 auto; }
  .profile-page__person-form-wrapper {
    flex-basis: 80%;
    min-width: 500px; }
  .profile-page__tabs-wrapper {
    margin: 10px; }
  .profile-page__profile-picture {
    align-self: center;
    width: 100px;
    height: 100px;
    margin: 10px 4px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
  .profile-page__add-profile-picture-label {
    align-self: center;
    margin: 0 4px;
    color: #6a6868;
    font-size: 0.7em;
    cursor: pointer; }
  @media only screen and (max-width: 768px) {
    .profile-page__tabs-wrapper {
      margin: 10px 0 0; }
    .profile-page__card {
      justify-content: center; }
    .profile-page__profile-picture-container {
      width: 100%;
      margin-left: 0; }
    .profile-page__person-form-wrapper {
      flex-basis: 100%;
      min-width: inherit; } }
