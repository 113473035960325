/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.recommended-competences {
  display: flex;
  flex-direction: column; }
  .recommended-competences__title {
    margin: 10px;
    margin-top: 30px;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: 1.5rem; }
  .recommended-competences__cards {
    display: flex;
    flex-wrap: wrap; }
  .recommended-competences__card-wrapper {
    display: flex;
    flex: 1 0 50%;
    align-items: stretch; }
    @media screen and (max-width: 680px) {
      .recommended-competences__card-wrapper {
        flex: 0 0 100%; } }
  .recommended-competences__card {
    display: flex;
    flex: 1 1 auto;
    box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
    margin: 10px;
    border-radius: 6px;
    background-color: var(--color-main-dark);
    color: white; }
  .recommended-competences__card-cover {
    display: flex;
    flex: 0 0 100px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    min-height: 100px;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    font-size: 50px; }
    .recommended-competences__card-cover i {
      margin-left: 10px; }
  .recommended-competences__card-description {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    min-width: 200px;
    padding: 10px; }
  .recommended-competences__card-title {
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.5; }
  .recommended-competences__card-subtitle {
    font-size: 1rem;
    line-height: 1.5; }
  .recommended-competences__status {
    display: flex;
    justify-content: center;
    color: var(--color-main-dark);
    font-size: 1rem; }
