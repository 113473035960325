/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.employees-list {
  display: flex;
  flex-wrap: wrap; }
  .employees-list__card-wrapper {
    overflow-x: hidden;
    display: flex;
    flex: 0 0 33.333333%;
    padding: 10px; }
    .employees-list__card-wrapper.absent {
      opacity: 0.4; }
    @media screen and (max-width: 1400px) {
      .employees-list__card-wrapper {
        flex: 0 0 50%; } }
    @media screen and (max-width: 950px) {
      .employees-list__card-wrapper {
        flex: 0 0 100%;
        padding: 6px 10px; } }
    @media screen and (max-width: 768px) {
      .employees-list__card-wrapper {
        flex: 0 0 50%; } }
    @media screen and (max-width: 640px) {
      .employees-list__card-wrapper {
        flex: 0 0 100%; } }
