/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.chipses {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 50px;
  padding: 0; }
  .chipses--mobile {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 18px;
    font-size: 1rem; }
    .chipses--mobile .chipses__container {
      justify-content: center; }
    .chipses--mobile .chipses__chips {
      font-size: 0.9rem; }
  .chipses__container {
    display: flex;
    flex-wrap: wrap;
    width: 70%; }
  .chipses__chips {
    margin-right: 7px;
    margin-bottom: 7px;
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 8px 14px;
    background: #fff;
    color: #333;
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer; }
    .chipses__chips:hover {
      border: 1px solid rgba(0, 0, 0, 0.3);
      color: #000; }
    .chipses__chips--active {
      border: 1px solid #fff;
      background: var(--color-action);
      color: #fff; }
      .chipses__chips--active:hover {
        border: 1px solid #fff;
        color: white; }
