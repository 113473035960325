/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.course-calendar-card,
.course-calendar-card__loading {
  position: relative;
  display: flex;
  flex: 1 1 auto; }
  .course-calendar-card__date,
  .course-calendar-card__loading__date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 60px;
    color: var(--color-main-dark);
    line-height: 1.2; }
    .course-calendar-card__date__day,
    .course-calendar-card__loading__date__day {
      font-size: 2em; }
    .course-calendar-card__date__daytext,
    .course-calendar-card__loading__date__daytext {
      font-weight: 700;
      font-size: 0.8rem; }
  .course-calendar-card__dropdown,
  .course-calendar-card__loading__dropdown {
    z-index: 10; }
  .course-calendar-card .course-sign,
  .course-calendar-card__loading .course-sign {
    align-self: flex-start;
    margin-right: auto;
    margin-left: 0; }
  .course-calendar-card__card,
  .course-calendar-card__loading__card {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: wrap; }
    .course-calendar-card__card a,
    .course-calendar-card__loading__card a {
      color: inherit; }
  .course-calendar-card__text,
  .course-calendar-card__loading__text {
    display: block;
    flex: 1 1;
    flex-basis: 270px;
    color: #666;
    font-size: 0.9rem;
    white-space: normal; }
    .course-calendar-card__text__title,
    .course-calendar-card__loading__text__title {
      font-weight: 700; }
    .course-calendar-card__text__details,
    .course-calendar-card__loading__text__details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 5px; }
      .course-calendar-card__text__details .course-calendar-card__detail,
      .course-calendar-card__loading__text__details .course-calendar-card__detail {
        margin-right: 30px; }
        .course-calendar-card__text__details .course-calendar-card__detail i,
        .course-calendar-card__loading__text__details .course-calendar-card__detail i {
          margin-right: 5px; }
        .course-calendar-card__text__details .course-calendar-card__detail:last-child,
        .course-calendar-card__loading__text__details .course-calendar-card__detail:last-child {
          margin-right: 0; }
    .course-calendar-card__text__description,
    .course-calendar-card__loading__text__description {
      margin-top: 20px; }
  .course-calendar-card__detail,
  .course-calendar-card__loading__detail {
    flex: 1 1 150px; }
    .course-calendar-card__detail:first-child,
    .course-calendar-card__loading__detail:first-child {
      flex-grow: 0;
      flex-basis: content; }

.course-calendar-card__loading svg {
  width: 450px;
  height: 104px; }
