/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.employee-preview {
  display: flex;
  flex-direction: row; }
  .employee-preview__card-wrapper {
    float: left;
    clear: both;
    flex: 0 0 300px;
    width: 275px; }
    .employee-preview__card-wrapper .employee-card {
      margin-right: 20px;
      padding: 30px 20px; }
  .employee-preview__competences-wrapper {
    flex: 1 1 0;
    padding-left: 300px; }
    .employee-preview__competences-wrapper .events-list__item {
      width: 100%; }
  .employee-preview__competences-header {
    display: flex;
    margin: 10px; }
  .employee-preview__competences-header-title {
    flex-grow: 1;
    margin: 0; }
  .employee-preview__tasks {
    float: left;
    clear: both;
    width: 100%; }
  .employee-preview__competences-header-actions {
    position: relative;
    display: flex; }
  .employee-preview__status-message {
    margin: 10px;
    color: var(--color-main-dark);
    font-weight: 700;
    text-align: center; }
  .employee-preview__add-menu {
    position: absolute;
    top: 40px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: 150px;
    box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
    border: 2px solid #ccc;
    border-radius: 8px;
    background: white; }
  .employee-preview__add-menu-item {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    border-bottom: none;
    padding: 18px 24px;
    padding-left: 8px;
    background: none;
    color: #000;
    font-weight: 500;
    text-align: left;
    cursor: pointer; }
    .employee-preview__add-menu-item:hover {
      background-color: #f2f2f2; }
    .employee-preview__add-menu-item:disabled {
      opacity: 0.2; }
  .employee-preview__roles-modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(77, 77, 77, 0.7); }
  .employee-preview__roles-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 400px;
    min-height: 300px;
    margin: auto;
    border-radius: 8px;
    padding: 15px;
    background: white; }
  .employee-preview__roles-modal-close-button {
    position: absolute;
    top: 17px;
    right: 17px; }
  .employee-preview__roles-modal-header {
    margin: 5px 0; }
  @media only screen and (max-width: 768px) {
    .employee-preview {
      flex-direction: column; }
      .employee-preview__card-wrapper {
        float: none;
        clear: none;
        display: flex;
        order: 2; }
      .employee-preview__competences-wrapper {
        width: 100%;
        margin-top: 30px;
        padding-left: 0; }
      .employee-preview .employee-card {
        margin-right: auto;
        margin-bottom: 20px; } }
