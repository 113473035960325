/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.login-form {
  background-color: white; }
  .login-form__container {
    width: 100%; }
  .login-form .login-form__input {
    border: 1px solid #ccc; }
  .login-form .form-input__label {
    font-size: 1rem !important; }
  .login-form__submit-button-wrapper {
    margin: 10px 0;
    padding: 10px; }
    .login-form__submit-button-wrapper::before {
      content: '';
      flex: 1 0 50%; }
  .login-form__submit-button.btn {
    padding: 14px 20px;
    background: #3a3a3c;
    font-size: 1rem !important; }
    .login-form__submit-button.btn:hover {
      text-decoration: underline; }
  .login-form__forgot-button.btn {
    box-shadow: none;
    margin-left: 20px;
    background: none;
    color: #25a9e1;
    font-size: 1rem !important; }
  .login-form__invalid-credentials {
    color: red;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 7px;
    font-size: 1rem; }
    .login-form__invalid-credentials__hidden {
      max-height: 0;
      overflow: hidden;
      margin: 0; }
    .login-form__invalid-credentials__visible {
      transition: max-height 1s ease-out;
      max-height: 100px; }
