/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.activity-list {
  flex: 1 1 auto;
  margin-bottom: 50px;
  font-size: 1rem; }
  .activity-list__header {
    padding: 15px;
    font-weight: 700;
    font-size: 1.2rem; }
  .activity-list__tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 4px; }
    .activity-list__tabs__tab {
      flex: 0 0 auto;
      margin: 0 4px;
      padding: 6px 14px;
      cursor: pointer; }
      .activity-list__tabs__tab:hover {
        border-bottom: 1px solid var(--color-header-link-underline); }
      .activity-list__tabs__tab.active {
        border-bottom: 2px solid var(--color-header-link-underline);
        color: #000; }
  .activity-list__element {
    position: relative;
    display: flex;
    align-items: center;
    margin: 16px 0; }
    .activity-list__element.past {
      color: #D04B40; }
      .activity-list__element.past .activity-list__circle {
        border-color: #cf1322;
        background-color: #ffccc7; }
    .activity-list__element.not-past .activity-list__circle {
      border-color: #EEAF15; }
  .activity-list__loader {
    width: 400px;
    height: 40px; }
  .activity-list__activity-description {
    font-weight: 500;
    font-size: 0.9rem; }
    .activity-list__activity-description__link {
      color: #D04B40 !important; }
  .activity-list__activity-timestamp {
    float: right;
    margin-right: 20px;
    margin-left: auto;
    font-size: 0.8rem;
    white-space: nowrap; }
    .activity-list__activity-timestamp-wrapper {
      overflow: visible;
      flex: 0 0 auto;
      width: 96px; }
  .activity-list__circle {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    margin: auto;
    border: 2px solid #a0a0a0;
    border-radius: 50%;
    background: #d4d4d4; }
    .activity-list__circle-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      width: 16px;
      margin: -16px 0;
      margin-right: 30px; }
      .activity-list__circle-wrapper::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        z-index: -1;
        display: block;
        width: 0;
        border-left: 1px solid #a0a0a0; }
  .activity-list__element:first-of-type .activity-list__circle-wrapper::after {
    top: 50%; }
  .activity-list__element:last-of-type .activity-list__circle-wrapper::after {
    bottom: 50%; }
  .activity-list__person-avatar {
    flex: 0 0 auto;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border: 1px solid #979797;
    background-color: #d8d8d8 !important; }
    .activity-list__person-avatar .person-avatar__initials {
      font-size: 0.9rem !important; }
  .activity-list__competence-link, .activity-list__competence-link:active, .activity-list__competence-link:visited {
    color: inherit;
    font-weight: inherit;
    text-decoration: none; }
  .activity-list__competence-link:hover {
    color: var(--color-main-dark);
    text-decoration: underline; }
  .activity-list__person-name, .activity-list__person-name:active, .activity-list__person-name:visited {
    color: var(--color-main-dark);
    font-weight: 700;
    text-decoration: none; }
  .activity-list__person-name:hover {
    text-decoration: underline; }
