/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.employee-add__card {
  position: relative;
  display: flex;
  box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
  margin: 10px;
  border-radius: 8px;
  padding: 40px 20px;
  background: white; }

.employee-add__buttons-wrapper {
  position: absolute;
  right: 40px;
  bottom: 40px;
  padding-top: 20px; }

.employee-add__bottom-checkbox-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 70px 0;
  padding-left: 30px; }

.employee-add__checkbox-label {
  margin-left: 10px; }

.employee-add__form {
  display: flex;
  justify-content: center; }
  .employee-add__form-inner-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 70%; }

.employee-add__divider {
  width: 100%;
  height: 1px;
  margin: 40px 0;
  background-color: #b2b2b2; }

.employee-add .hlp {
  padding: 7px;
  color: #999;
  font-size: 0.8rem; }
  .employee-add .hlp strong {
    color: black; }

.employee-add__input-wrapper {
  width: 45%; }

.employee-add__input-full-wrapper {
  width: 100%; }

.employee-add__input {
  border-radius: 10px; }
  .employee-add__input--multiselect {
    /* Removes scrollbar for Firefox */
    overflow: -moz-scrollbars-none;
    /* Removes scrollbar for IE +10 */
    -ms-overflow-style: none;
    max-height: 200px;
    border-color: #979797;
    padding: 10px 0 30px; }
    .employee-add__input--multiselect::-webkit-scrollbar {
      /* Removes scrollbar for Chrome, Safari and newer versions of Opera */
      width: 0 !important; }

.employee-add__status-message {
  margin: 10px;
  color: var(--color-main-dark);
  font-weight: 700;
  text-align: center; }

@media screen and (max-width: 1100px) {
  .employee-add__form-inner-wrapper {
    width: 80%; } }

@media screen and (max-width: 1000px) {
  .employee-add__form-inner-wrapper {
    width: 90%; } }

@media screen and (max-width: 950px) {
  .employee-add__form {
    width: 100%; }
    .employee-add__form-inner-wrapper {
      flex-direction: column;
      width: 70%; }
  .employee-add__bottom-checkbox-wrapper {
    padding-left: 10px; }
  .employee-add__input-wrapper {
    width: 100%; } }

@media screen and (max-width: 640px) {
  .employee-add__form-inner-wrapper {
    width: 90%; } }
