/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.news-list {
  max-width: 1024px;
  margin: 0 auto;
  margin-top: 30px; }
  .news-list .carousel {
    position: relative;
    height: 280px;
    margin-bottom: 100px; }
    .news-list .carousel__slide {
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: visible; }
    .news-list .carousel__inner-slide {
      clear: both;
      overflow: visible;
      height: auto; }
    .news-list .carousel__slider {
      position: absolute;
      top: 0;
      right: 52px;
      bottom: 0;
      left: 52px; }
      @media screen and (max-width: 768px) {
        .news-list .carousel__slider {
          right: 32px;
          left: 32px; } }
      .news-list .carousel__slider-tray-wrapper {
        margin-right: -2px; }
    .news-list .carousel__dot-group {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    @media screen and (max-width: 480px) {
      .news-list .carousel {
        margin: 0 -10px; } }
  .news-list__title {
    margin: 20px 0 30px;
    margin-left: 10px;
    font-weight: 400; }
  .news-list__news-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }
  .news-list__item {
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: left;
    width: auto;
    margin: 0 auto;
    cursor: default; }
    .news-list__item--clickable {
      cursor: pointer; }
    .news-list__item--center .news-list__item {
      align-items: center; }
    .news-list__item-wrapper {
      position: relative;
      right: 0;
      left: 0;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0 auto; }
    .news-list__item-image {
      flex: 1 1 auto;
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      margin: auto;
      margin-bottom: 0;
      margin-left: 0;
      border: 8px solid #fff;
      object-fit: scale-down; }
      .news-list__item--center .news-list__item-image {
        margin-left: auto; }
      .news-list__item-image__wrapper {
        position: relative;
        overflow: hidden;
        display: flex;
        flex: 1 1 auto;
        width: 100%;
        width: 200px;
        height: 150px; }
        .news-list__item--center .news-list__item-image__wrapper {
          margin: 0 auto; }
        .news-list__item--small .news-list__item-image__wrapper {
          height: auto;
          max-height: 126px; }
      .news-list__item-image__content {
        width: 100%;
        align-self: stretch;
        width: 100%;
        height: 100%;
        background-size: cover; }
    .news-list__item-title {
      flex: 0 1 auto;
      max-width: 250px;
      line-height: 1.35;
      margin: 0 auto;
      margin-top: 10px;
      position: absolute;
      bottom: 0px;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      text-align: left;
      padding: 10px;
      color: white;
      min-height: 60px;
      font-size: 0.9rem;
      font-weight: 400; }
    .news-list__item-redirect-btn {
      flex: 0 1 auto;
      margin-right: auto;
      margin-left: 0;
      box-shadow: none;
      border-radius: 10px;
      background: none !important;
      color: white;
      font-weight: 700;
      font-size: 0.8rem;
      line-height: 27px !important;
      cursor: pointer;
      border: 1px solid #ddd;
      height: 100%;
      width: 100%;
      background: none;
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      height: 42px;
      text-align: left; }
      .news-list__item-redirect-btn a {
        background: none; }
    .news-list__item--active .news-list__item-image {
      border-color: var(--color-main-dark); }
    .news-list__item--active .news-list__item-title {
      font-weight: 600; }
  .news-list .indicator-dots {
    margin-bottom: 50px; }
