/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.action-bar {
  position: relative; }
  .action-bar .action-bar-items {
    position: absolute;
    top: 50px;
    z-index: 1;
    min-width: 100%;
    box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    border: none;
    border-radius: 8px;
    background-color: #fff;
    font-weight: 800;
    font-size: 1rem;
    line-height: 2; }
    .action-bar .action-bar-items .item {
      display: block;
      border-bottom: 1px solid #e9e9e9;
      padding: 10px 20px;
      background-color: #fff;
      color: #333;
      cursor: pointer; }
      .action-bar .action-bar-items .item:hover {
        background-color: #f2f2f2; }
      .action-bar .action-bar-items .item:first-child {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px; }
      .action-bar .action-bar-items .item:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px; }
      .action-bar .action-bar-items .item svg {
        margin-right: 10px; }
  .action-bar .action-bar-action {
    position: relative;
    box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    background-color: #fff;
    font-weight: 800;
    font-size: 1rem;
    line-height: 2;
    cursor: pointer; }
    .action-bar .action-bar-action:hover {
      background-color: #f2f2f2; }
    .action-bar .action-bar-action i {
      position: absolute;
      top: 50%;
      right: 25px;
      display: block;
      line-height: 0; }
