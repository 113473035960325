/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.employees-checklist {
  flex: 1 1 auto;
  padding: 10px; }
  .employees-checklist__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    color: #000; }
  .employees-checklist__avatar {
    flex: 0 0 40px;
    margin: auto 0;
    margin-left: 10px; }
    .employees-checklist__avatar .person-avatar {
      width: 45px;
      height: 45px;
      margin: auto; }
  .employees-checklist__dott {
    flex: 0 0 28px;
    width: 28px;
    height: 28px;
    margin: 8px 20px;
    border: 2px solid #999;
    border-radius: 50%;
    background: #fff; }
    .employees-checklist__dott-disabled {
      opacity: 0.5; }
    .employees-checklist__dott:hover {
      background: #f2f2f2; }
  .employees-checklist__dott-done {
    flex: 0 0 28px;
    width: 28px;
    height: 28px;
    margin: 8px 20px;
    border: 2px solid #006200;
    border-radius: 50%;
    background: #6dc995; }
    .employees-checklist__dott-done i {
      padding-left: 6px;
      color: #2e5b41;
      font-size: 0.8rem; }
  .employees-checklist__card {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
    border-radius: 5px;
    padding: 10px;
    background-color: #fff; }
  .employees-checklist__text {
    margin: auto 0; }
    .employees-checklist__text__name {
      color: var(--color-main-dark);
      font-weight: 700; }
      .employees-checklist__text__name:hover {
        text-decoration: underline; }
