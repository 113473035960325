/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.extra-chart {
  position: relative;
  overflow: visible;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 0;
  margin: 0 20px; }
  @media screen and (max-width: 1100px) {
    .extra-chart {
      margin-bottom: 30px; } }
  .extra-chart__title {
    flex: 0 0 auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #6a6868;
    font-weight: 700;
    font-size: 1.8rem; }
  .extra-chart__subtitle {
    position: relative;
    flex: 0 0 auto;
    margin: 0 auto;
    color: #999;
    font-size: 1rem; }
  .extra-chart__canvas-wrapper {
    position: relative;
    flex: 1 1 100%;
    width: 100%;
    margin: 0; }
    .extra-chart__canvas-wrapper svg {
      position: absolute;
      flex: 1 1 100%;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      margin: 0; }
  .extra-chart__progress {
    position: relative;
    display: block;
    display: flex;
    flex: 1 1 auto; }
    .extra-chart__progress-main {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #6a6868;
      font-weight: 700;
      font-size: 36px;
      line-height: 0;
      transform: translateX(-50%);
      vertical-align: middle; }
    @media screen and (max-width: 1100px) {
      .extra-chart__progress-main {
        font-size: 26px; } }
