/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.course-sign {
  align-self: center;
  margin-right: 0;
  margin-left: auto;
  white-space: nowrap; }
  .course-sign__drop {
    z-index: 100 !important; }
  .course-sign__dropdown {
    position: relative;
    z-index: 10; }
    .course-sign__dropdown-btn {
      border-radius: 10px; }
      .course-sign__dropdown-btn--main {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-right: 25px;
        padding-left: 30px; }
      .course-sign__dropdown-btn--chevron {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-left: 1px solid #fff;
        padding-right: 10px;
        padding-left: 10px; }
    .course-sign__dropdown-options {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 200px;
      box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
      margin-top: 5px;
      border: 1px solid #666;
      border-radius: 10px;
      background: #fcfcfc;
      font-size: 0.8rem; }
      @media (min-width: 576px) {
        .course-sign__dropdown-options {
          right: 0; } }
    .course-sign__dropdown-option {
      width: 100%;
      border-radius: 0;
      border-top: 1px solid #b2b2b2;
      padding-top: 12px;
      padding-bottom: 12px;
      font-weight: 500;
      text-align: left;
      cursor: pointer; }
      .course-sign__dropdown-option i {
        margin: 0 15px; }
      .course-sign__dropdown-option:first-of-type {
        border-top: 0; }
  .course-sign__modal {
    position: relative;
    width: 100%;
    border-radius: 10px;
    padding: 30px 40px;
    background: #fcfcfc; }
    @media (min-width: 768px) {
      .course-sign__modal {
        width: 50vw; } }
    .course-sign__modal-title {
      margin-top: 0; }
    .course-sign__modal-subtitle {
      margin: 0;
      font-size: 1.2rem; }
    .course-sign__modal .form-multiselect {
      padding: 0; }
    .course-sign__modal .modal-form {
      margin-bottom: 50px; }
      .course-sign__modal .modal-form__buttons {
        position: absolute;
        right: 40px;
        bottom: 30px; }
        .course-sign__modal .modal-form__buttons .btn {
          padding: 8px 40px; }
    .course-sign__modal-results:nth-last-child(1) {
      margin-bottom: 60px; }
    .course-sign__modal-results__container {
      display: flex;
      flex-direction: column;
      margin: 40px;
      margin-right: 0;
      margin-bottom: 20px; }
    .course-sign__modal-results__item {
      margin-top: 5px;
      margin-left: 20px; }
      .course-sign__modal-results__item--correct i {
        margin-right: 8px;
        color: green;
        font-size: 0.9rem; }
      .course-sign__modal-results__item--error i {
        margin-right: 15px;
        color: #990000; }
  .course-sign__search-wrapper {
    margin-right: -10px;
    margin-left: -10px; }
