/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.messages-list {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 20px;
  background: white; }
  .messages-list .nomsg {
    padding-top: 50px;
    color: #ccc;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase; }

.message {
  display: flex;
  flex-direction: column; }
  .message:not(:last-child) {
    border-bottom: 1px solid #e5e5e5; }
  .message--expanded {
    background: #fafafa; }
    .message--expanded:first-child {
      border-top-right-radius: inherit;
      border-top-left-radius: inherit; }
    .message--expanded:last-child {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit; }
  .message__header {
    display: flex;
    align-items: baseline;
    padding: 10px 22px; }
    .message__header:hover {
      background: whitesmoke;
      cursor: pointer; }
  .message__info {
    overflow: hidden;
    display: flex;
    flex-grow: 1;
    flex-direction: column; }
  .message__subject {
    overflow: hidden;
    min-width: 0;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .message__subject--expanded {
      white-space: pre-wrap; }
  .message__date {
    margin-right: 10px;
    color: #999;
    font-size: 0.8rem;
    text-align: right; }
  .message__collapse {
    display: flex;
    justify-content: center;
    align-items: baseline;
    border: none;
    padding: 10px;
    background: none;
    color: #4c4c4c;
    font-size: 16px;
    cursor: pointer; }
  .message__meta {
    display: flex;
    flex-direction: column;
    margin-top: 10px 0;
    padding: 0 22px;
    line-height: 1.5; }
  .message__recipent {
    margin-right: 10px;
    color: #666;
    font-size: 0.8rem;
    font-size: 14px; }
    .message__recipent div {
      display: inline-block;
      width: 40px;
      margin-right: 10px;
      text-align: right; }
    .message__recipent span {
      font-weight: 600; }
    .message__recipent p {
      font-weight: 600; }
    .message__recipent a {
      text-decoration: none; }
      .message__recipent a, .message__recipent a:active, .message__recipent a:visited {
        color: var(--color-link); }
  .message__content {
    padding: 0 22px;
    line-height: 1.2;
    white-space: pre-wrap; }
