@import '~@styles/mixins.scss';

$mobile-width: 480px;
$tablet-small-width: 640px;
$tablet-width: 768px;
$tablet-width-course-catalog: 1000px;
$desktop-small-width: 950px;
$desktop-medium-width: 1400px;

$course-catalog-one-column-layout: 485px;
$course-catalog-two-column-layout: 1150px;
$course-catalog-one-column-layout-for-extra-wide: 650px;

$separator-border: 1px solid #e9e9e9;
$separator-border-dark: 1px solid #c8c8c8;

$menu-type: standard;
$menu-height: 125px;

$form-field-wrapper-padding: 7px;

$color-background: #fff;

$color-black: #000;
$color-grey-10: #191919;
$color-grey-20: #333;
$color-grey-30: #4c4c4c;
$color-grey-40: #666;
$color-grey-45: #727272;
$color-grey-50: #7f7f7f;
$color-grey-60: #999;
$color-grey-70: #b2b2b2;
$color-grey-75: #bfbfbf;
$color-grey-80: #ccc;
$color-grey-85: #d8d8d8;
$color-grey-90: #e5e5e5;
$color-grey-95: #f2f2f2;
$color-grey-96: #f4f4f4;
$color-grey-97: #f7f7f7;
$color-grey-98: #f9f9f9;
$color-grey-99: #fcfcfc;
$color-white: #fff;
$color-gray-fields: #979797;

$color-points: #618da7;
$color-pdf: #006aac;
$color-blue: #078ada;
$color-green: green;
$color-red: red;
$color-orange: #ef591b;

$color-progressbar-fill: #2f93e0;
$color-progressbar-fill-2: #e12b5f;
$color-progressbar-fill-empty: #ccc;

$color-completed: #81c784;
$color-not-completed: #ef5350;

$font-size-s: 0.8rem;
$font-size-sm: 0.9rem;
$font-size-m: 1rem;
$font-size-ml: 1.1rem;
$font-size-l: 1.2rem;
$font-size-xl: 1.5rem;
$font-size-xxl: 2rem;

$font-color: $color-black;
$font-color-light: #6a6868;

$card-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
$card-small-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);

$card-border: 1px solid $color-grey-80;
$card-separator: 1px solid $color-grey-95;

$border-radius-modal: 8px;

$fonts: 'Quattrocento Sans', Helvetica, Arial, Sans-Serif;

/*
  stylelint-disable
*/
:export {
  colorblue: $color-blue;
  colorcompleted: $color-completed;
  colornotcompleted: $color-not-completed;
  colorgrey80: $color-grey-80;
}
