/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.person-search-add-form {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .person-search-add-form__input-wrapper {
    width: 100%; }
  .person-search-add-form__input {
    width: 100%; }
  .person-search-add-form .search-bar__label {
    box-shadow: none;
    border: 1px solid #979797; }
  .person-search-add-form .search-bar__input {
    padding: 8px 10px;
    font-size: 14px; }
  .person-search-add-form__submit-button-wrapper {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding-right: 5px;
    text-align: right; }
  .person-search-add-form__dropdown-wrapper {
    position: absolute;
    top: 100%;
    right: 10px;
    left: 10px;
    z-index: 9999;
    margin-top: -11px;
    padding: 0; }
    .person-search-add-form__dropdown-wrapper .search-bar__dropdown {
      overflow-y: auto;
      max-height: 400px;
      box-shadow: none;
      border: 1px solid #979797;
      border-top-right-radius: 0;
      border-top-left-radius: 0; }
