/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.edit-pass {
  padding-top: 0px;
  overflow-y: auto; }
  .edit-pass .form-input {
    padding: 0; }
  .edit-pass .hlp {
    margin-bottom: 10px;
    color: #999;
    font-size: 0.8rem; }
    .edit-pass .hlp strong {
      font-size: 0.9rem;
      color: black; }
  .edit-pass .old-password {
    margin-bottom: 20px; }
    .edit-pass .old-password .form-input {
      width: 100%; }
      @media (min-width: 576px) {
        .edit-pass .old-password .form-input {
          width: 41%; } }
  .edit-pass__heading {
    color: initial;
    margin: 30px 0 0;
    font-size: 1.2rem; }
  .edit-pass__heading-username {
    margin-top: 10px; }
  .edit-pass__heading-description {
    margin-bottom: 4px; }
  .edit-pass__description {
    margin: 5px 0 30px;
    color: #4c4c4c;
    font-size: 1rem; }
  .edit-pass__description-norsk-butikkdrift {
    margin-top: 4px; }
  .edit-pass__inputs-wrapper {
    display: flex;
    width: 100%; }
    .edit-pass__inputs-wrapper :first-child {
      margin-right: 20px; }
    @media screen and (max-width: 480px) {
      .edit-pass__inputs-wrapper {
        flex-direction: column; }
        .edit-pass__inputs-wrapper :first-child {
          margin-right: 0;
          margin-bottom: 10px; } }
  .edit-pass__buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px; }
