/*
  stylelint-disable
*/
@import url("//fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700");
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

@font-face {
  font-style: normal;
  font-family: 'Quattrocento Sans';
  src: url(../../assets/fonts/QuattrocentoSans-Regular.ttf) format("truetype"); }

@font-face {
  font-weight: bold;
  font-family: 'Quattrocento Sans';
  src: url(../../assets/fonts/QuattrocentoSans-Bold.ttf) format("truetype"); }

@font-face {
  font-style: italic;
  font-family: 'Quattrocento Sans';
  src: url(../../assets/fonts/QuattrocentoSans-Italic.ttf) format("truetype"); }

@font-face {
  font-style: italic;
  font-weight: bold;
  font-family: 'Quattrocento Sans';
  src: url(../../assets/fonts/QuattrocentoSans-BoldItalic.ttf) format("truetype"); }

html,
body,
#root {
  overflow: hidden;
  margin: 0;
  padding: 0; }

body,
button {
  font-family: 'Quattrocento Sans', sans-serif; }

button {
  cursor: pointer; }

.app-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff; }
  .app-wrapper .alert {
    position: relative;
    margin: 20px;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    padding: 0.75rem 1.25rem; }
    .app-wrapper .alert.alert-warning {
      border-color: #ffeeba;
      background-color: #fff3cd;
      color: #856404; }
    .app-wrapper .alert.alert-info {
      border-color: #bee5eb;
      background-color: #d1ecf1;
      color: #0c5460; }
    .app-wrapper .alert .alert-heading {
      color: inherit; }
  .app-wrapper ul.tabs {
    display: inline-block;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 0.9rem; }
    @media (min-width: 576px) {
      .app-wrapper ul.tabs {
        margin-top: 0;
        margin-right: 20px; } }
    .app-wrapper ul.tabs li {
      position: relative;
      float: left;
      align-content: center;
      padding-top: 30px;
      list-style: none;
      vertical-align: center; }
      .app-wrapper ul.tabs li button {
        display: block;
        height: 100%;
        margin: 0 4px;
        border-radius: 0;
        padding: 6px 14px;
        line-height: 1.5em;
        cursor: pointer; }
        .app-wrapper ul.tabs li button:hover {
          border-bottom: 1px solid #ef5921; }
      .app-wrapper ul.tabs li.active button {
        border-bottom: 2px solid #ef5921; }
  .app-wrapper .saving-data {
    position: absolute;
    top: -4px;
    left: 0;
    z-index: 44000;
    width: 200px;
    box-shadow: 2px 2px 5px #555;
    border-radius: 5px;
    padding: 10px 30px;
    background-color: white;
    text-align: center; }
  .app-wrapper__menu {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2000;
    height: 125px; }
    @media screen and (max-width: 768px) {
      .app-wrapper__menu {
        height: 65px; } }
  .app-wrapper .info__chip {
    clear: both;
    width: 100%;
    margin: 0 auto;
    font-weight: 200;
    font-size: 0.8rem;
    text-align: center; }
    .app-wrapper .info__chip .chip {
      display: block;
      width: 36px;
      height: 36px;
      margin: 10px auto 30px;
      border: 1px solid #ccc;
      border-radius: 36px;
      padding: 0 0 9px 0;
      background-color: white;
      color: #606060;
      font-weight: 600;
      font-size: 1.2rem;
      text-align: center;
      cursor: pointer; }
      .app-wrapper .info__chip .chip--loading {
        line-height: 36px; }
      .app-wrapper .info__chip .chip:hover {
        background-color: #eee;
        color: #333;
        font-weight: 600; }
      .app-wrapper .info__chip .chip--active {
        background-color: var(--color-main-dark);
        color: #eee; }
        .app-wrapper .info__chip .chip--active:hover {
          opacity: 0.7;
          background-color: var(--color-main-dark);
          color: #eee; }
  .app-wrapper__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto; }
    .app-wrapper__content:nth-child(2) {
      top: 125px; }
      @media screen and (max-width: 768px) {
        .app-wrapper__content:nth-child(2) {
          top: 90px; } }
  .app-wrapper .loading {
    width: 100%; }
    .app-wrapper .loading .line-content {
      height: 14px;
      margin-top: 14px;
      margin-left: 30px;
      background: #e9e9e9;
      animation: pulse 2s infinite; }
      .app-wrapper .loading .line-content.line-1 {
        width: 90%; }
      .app-wrapper .loading .line-content.line-2 {
        width: 87%; }
      .app-wrapper .loading .line-content.line-3 {
        width: 66%; }
      .app-wrapper .loading .line-content.line-4 {
        width: 77%; }
      .app-wrapper .loading .line-content.line-5 {
        width: 78%; }
    .app-wrapper .loading .image-content {
      height: 120px;
      margin-left: 30px;
      border-radius: 5px;
      background: #ededed; }
    .app-wrapper .loading .h1-content {
      width: 72%;
      height: 30px;
      margin-top: 40px;
      margin-left: 30px;
      padding-left: 20px;
      background: #e9e9e9;
      animation: pulse 2s infinite; }
  .app-wrapper .task-list {
    width: 100%;
    box-shadow: 2px 2px 4px #ccc;
    margin: 0;
    padding: 0; }
    .app-wrapper .task-list__item {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #ccc;
      padding: 0;
      background-color: White;
      list-style: none;
      font-size: 0.9rem; }
      .app-wrapper .task-list__item:hover {
        background-color: #fafafa;
        cursor: pointer; }
      .app-wrapper .task-list__item:first-child {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px; }
      .app-wrapper .task-list__item:last-child {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px; }
      .app-wrapper .task-list__item-icon {
        margin: 0 10px 0 15px; }

.clickable-card {
  cursor: pointer; }

.expand-button {
  width: 40px;
  height: 40px;
  margin: 20px auto 0;
  border: 1px solid #6a6868;
  border-radius: 50%;
  padding-left: 2px;
  background-color: #fff;
  color: #6a6868;
  font-size: 15px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 3px;
  cursor: pointer; }

.menu-two .app-wrapper__content:nth-child(2) {
  top: 60px; }

.menu-two .app-wrapper__menu {
  height: 60px; }
