/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.statistics-card {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  height: 125px;
  box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
  margin-top: auto;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 16px 10px;
  padding-right: 80px;
  background-color: #fff; }
  .statistics-card__wrapper {
    display: flex;
    flex: 0 1 auto; }
    @media screen and (max-width: 850px) {
      .statistics-card__wrapper {
        margin: 10px 0; } }
  .statistics-card__icon {
    display: flex;
    align-items: center;
    width: 65px;
    height: 65px;
    margin: auto;
    border-radius: 50%;
    color: #fff; }
    .statistics-card__icon i {
      width: 100%;
      font-size: 32px;
      text-align: center; }
    .statistics-card__icon-wrapper {
      flex: 0 0 auto;
      margin: 0 25px;
      margin-right: 50px; }
    @media screen and (max-width: 850px) {
      .statistics-card__icon {
        width: 60px;
        height: 60px; }
        .statistics-card__icon i {
          font-size: 28px; }
        .statistics-card__icon-wrapper {
          margin: 0 20px; } }
  .statistics-card__content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin-right: 10px;
    line-height: 1.4; }
    @media screen and (max-width: 850px) {
      .statistics-card__content {
        line-height: 1.3; } }
    .statistics-card__content__title {
      font-size: 0.9rem;
      text-transform: uppercase; }
    .statistics-card__content__score {
      font-weight: 700;
      font-size: 2.5em;
      line-height: 1.3;
      white-space: nowrap; }
    .statistics-card__content__details {
      color: #6a6868;
      font-size: 0.8rem; }
    .statistics-card__content__columns {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start; }
      .statistics-card__content__columns__item {
        display: flex;
        flex: 0 1 auto;
        flex-direction: column;
        margin-right: 20px;
        line-height: 1.4; }
        .statistics-card__content__columns__item:last-child {
          flex: 1 1 auto; }
        .statistics-card__content__columns__item > * {
          flex: 0 0 0; }
