/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.indicator-dots {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute; }
  .indicator-dots__wrapper {
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    height: 40px;
    display: flex;
    justify-content: space-between;
    min-width: 100px;
    max-width: 200px;
    margin: 0 auto; }
  .indicator-dots__arrows {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 160px;
    display: flex;
    justify-content: space-between; }
  .indicator-dots__dot {
    width: 12px;
    height: 12px;
    border: 1px solid #b2b2b2;
    border-radius: 100%;
    background: #e5e5e5;
    cursor: pointer;
    margin: 0 4px; }
    .indicator-dots__dot--active {
      border: 1px solid #e5e5e5;
      background: #fff; }
    .indicator-dots__dot__arrow {
      flex: 0 0 auto;
      padding: 4px 8px;
      font-size: 52px;
      vertical-align: middle;
      cursor: pointer;
      opacity: 0.3; }
      @media screen and (max-width: 768px) {
        .indicator-dots__dot__arrow {
          font-size: 42px;
          margin: 0 -10px; } }
