/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.mobile-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; }
  .mobile-menu__header {
    z-index: 4000;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background: var(--color-header-top); }
  .mobile-menu__logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px; }
  .mobile-menu__logo {
    width: 70px;
    margin-top: 4px;
    fill: #fff; }
    .mobile-menu__logo.theme10, .mobile-menu__logo.theme11, .mobile-menu__logo.theme12, .mobile-menu__logo.theme14, .mobile-menu__logo.theme16, .mobile-menu__logo.theme17, .mobile-menu__logo.theme19 {
      width: 50px;
      margin-top: 0; }
  .mobile-menu__bottom {
    display: block;
    width: 100%;
    height: 50px;
    background: var(--color-header-bottom); }
  .mobile-menu__profile {
    display: flex;
    align-items: center;
    width: 100%; }
  .mobile-menu__progress {
    width: 100%;
    max-width: 200px;
    margin-bottom: 20px;
    margin-left: 20px;
    padding-right: 70px;
    color: var(--color-header-text);
    font-size: 1.2rem; }
    .mobile-menu__progress .progress__bar-complete {
      background-color: #e12b5f; }
  .mobile-menu__avatar {
    width: 60px;
    min-width: 60px;
    height: 60px;
    margin-top: 5px;
    margin-left: 16px;
    cursor: pointer; }
    .mobile-menu__avatar-image {
      width: 100%;
      height: 100%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      border-radius: 100%;
      background-position: center;
      background-size: cover; }
  .mobile-menu__toggle-sidebar-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px; }
  .mobile-menu__toggle-sidebar-button {
    display: inline-block;
    box-shadow: none;
    margin: 5px 10px;
    border: none;
    padding: 7px 15px;
    background: none;
    color: var(--color-header-text);
    font-size: 24px;
    line-height: 1; }
    .mobile-menu__toggle-sidebar-button:active {
      border: none;
      background: rgba(116, 116, 116, 0.3); }
  .mobile-menu__sidebar {
    position: fixed;
    top: 40px;
    right: 0;
    z-index: 3000;
    width: 0;
    height: 100%;
    background: rgba(116, 116, 116, 0);
    transition: background 0.3s ease-in; }
    .mobile-menu__sidebar--opened {
      width: 100%;
      background: rgba(116, 116, 116, 0.5); }
  .mobile-menu__sidebar-content {
    position: absolute;
    top: -100vh;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--color-header-bottom);
    transition: top 0.3s linear; }
    .mobile-menu__sidebar-content--opened {
      top: 0; }
  .mobile-menu__search-wrapper {
    display: flex;
    margin: 15px; }
  .mobile-menu__search {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center; }
  .mobile-menu__search-input {
    width: 100%;
    height: 48px;
    border: none;
    border-radius: 3px;
    padding: 0 3em 0 0.75em;
    background: var(--color-main-dark-2);
    color: var(--color-input-text);
    font-size: 0.9rem;
    line-height: 1; }
    .mobile-menu__search-input::placeholder {
      color: var(--color-input-text); }
  .mobile-menu__search-icon {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    color: var(--color-input-text);
    font-size: 1rem; }
  .mobile-menu__sidebar-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px; }
  .mobile-menu__sidebar-link {
    display: inline-block;
    margin: 0 15px;
    padding: 0 7px;
    color: var(--color-header-text) !important;
    font-size: 1.2rem;
    line-height: 1.8;
    text-decoration: none; }
    .mobile-menu__sidebar-link--active {
      color: var(--color-header-text) !important;
      font-weight: 700; }
    .mobile-menu__sidebar-link-child {
      margin-left: 40px;
      font-size: 1.1rem; }
  .mobile-menu__sidebar-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px; }
  .mobile-menu__sidebar-button {
    display: inline-block;
    margin: 0 15px;
    border: none;
    padding: 0 7px;
    background: none;
    color: var(--color-header-text);
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.8; }
    .mobile-menu__sidebar-button i {
      opacity: 0.8;
      margin-right: 7px; }
    .mobile-menu__sidebar-button, .mobile-menu__sidebar-button:hover, .mobile-menu__sidebar-button:visited {
      text-decoration: none; }
    .mobile-menu__sidebar-button:hover i {
      opacity: 1; }
  .mobile-menu__sidebar-close-button {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    box-shadow: 1px 1px 4px rgba(116, 116, 116, 0.3);
    outline: none;
    border: none;
    border-radius: 40px;
    padding: 0;
    background: rgba(116, 116, 116, 0.3);
    color: white;
    line-height: 40px;
    text-align: center; }
    .mobile-menu__sidebar-close-button::before, .mobile-menu__sidebar-close-button::after {
      content: ' ';
      position: absolute;
      top: 18.5px;
      left: 7px;
      width: 26px;
      height: 3px;
      background-color: white; }
    .mobile-menu__sidebar-close-button::before {
      transform: rotate(45deg); }
    .mobile-menu__sidebar-close-button::after {
      transform: rotate(-45deg); }
