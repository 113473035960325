/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.course-catalog-switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%; }
  .course-catalog-switch .breadcrumbs {
    max-width: 1024px;
    padding: 0; }
  .course-catalog-switch__section {
    width: 100%;
    transition: flex 0.3s ease-out; }
  .course-catalog-switch__section.route_course-catalog {
    height: 100%;
    overflow-x: hidden; }
  @media only screen and (max-width: 768px) {
    .course-catalog-switch__section {
      width: 100%;
      margin: 0; } }
