/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.form-multiselect {
  padding: 7px; }
  .form-multiselect__label {
    padding: 0 4px;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 24px; }
  .form-multiselect__input {
    width: 100%;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 0;
    font-size: 1rem; }
    .rw-state-focus > .form-multiselect__input,
    .rw-state-focus > .form-multiselect__input:hover {
      box-shadow: none;
      border: 1px solid grey; }
    .form-multiselect__input--error {
      border-color: #c22;
      color: #c22; }
  .form-multiselect .rw-select-list {
    height: 200px; }
  .form-multiselect .rw-list-option.rw-state-focus {
    border-color: var(--color-main-dark); }
  .form-multiselect .rw-list-option:hover {
    background: linear-gradient(to top,rgba(255, 255, 255,0.9),rgba(255, 255, 255,0.9)) var(--color-main-dark); }
  .form-multiselect__error {
    color: #c22;
    font-size: 12px;
    line-height: 18px;
    text-align: center; }
