/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.form-input {
  padding: 7px; }
  .form-input__label {
    padding: 0 4px;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 24px; }
  .form-input__input {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #7f7f7f;
    border-radius: 10px;
    padding: 10px;
    font-size: 1rem; }
    .form-input__input--error {
      border-color: #c22;
      color: #c22; }
  .form-input__error {
    color: #c22;
    font-size: 12px;
    line-height: 18px;
    text-align: center; }
