/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.course-catalog-wrapper {
  display: flex;
  flex-direction: column; }
  .course-catalog-wrapper .course-catalog {
    display: flex;
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 0;
    flex-grow: 1;
    height: 100%; }
    .course-catalog-wrapper .course-catalog__courses-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 3px 0px; }
    .course-catalog-wrapper .course-catalog__courses {
      width: 100%;
      padding-top: 10px;
      padding-bottom: 8em;
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 1fr 1fr 1fr; }
      @media screen and (max-width: 1150px) {
        .course-catalog-wrapper .course-catalog__courses {
          grid-template-columns: 1fr 1fr;
          margin-bottom: 6px; } }
      @media screen and (max-width: 485px) {
        .course-catalog-wrapper .course-catalog__courses {
          grid-template-columns: 1fr;
          margin-bottom: 3px; } }
    .course-catalog-wrapper .course-catalog .courses_with_sidebar {
      grid-template-columns: 1fr 1fr; }
      @media screen and (max-width: 485px) {
        .course-catalog-wrapper .course-catalog .courses_with_sidebar {
          grid-template-columns: 1fr;
          margin-bottom: 3px; } }
    .course-catalog-wrapper .course-catalog__courses.extra_large_tiles {
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr; }
      @media screen and (max-width: 650px) {
        .course-catalog-wrapper .course-catalog__courses.extra_large_tiles {
          grid-template-columns: 1fr; } }
      @media screen and (max-width: 485px) {
        .course-catalog-wrapper .course-catalog__courses.extra_large_tiles {
          grid-template-columns: 1fr; } }
    .course-catalog-wrapper .course-catalog__course-card-wrapper, .course-catalog-wrapper .course-catalog__course-card-loader-wrapper {
      display: flex;
      align-items: stretch; }
      @media screen and (max-width: 950px) {
        .course-catalog-wrapper .course-catalog__course-card-wrapper, .course-catalog-wrapper .course-catalog__course-card-loader-wrapper {
          margin-bottom: 6px; } }
      @media screen and (max-width: 485px) {
        .course-catalog-wrapper .course-catalog__course-card-wrapper, .course-catalog-wrapper .course-catalog__course-card-loader-wrapper {
          margin-bottom: 3px; } }
    .course-catalog-wrapper .course-catalog__course-card-wrapper.new_card_style {
      flex: 0 0 35%;
      padding-bottom: 0.6em;
      margin-bottom: 0;
      min-width: 250px;
      margin-right: 0.3em; }
    @media screen and (max-width: 1000px) {
      .course-catalog-wrapper .course-catalog {
        flex-direction: column;
        width: 100%; }
        .course-catalog-wrapper .course-catalog__courses {
          border-top: none; }
        .course-catalog-wrapper .course-catalog__courses-wrapper {
          margin: 0;
          padding: 22px; } }
    .course-catalog-wrapper .course-catalog__courseevents-wrapper {
      margin-top: 0;
      padding: 0 10px; }
    .course-catalog-wrapper .course-catalog__course-calendar-card-wrapper {
      display: flex;
      flex: 1 1 100%;
      flex-direction: column;
      margin-bottom: 30px;
      padding-left: 30px; }
    .course-catalog-wrapper .course-catalog__calendar-title-month {
      margin-top: 60px;
      margin-bottom: 20px;
      text-transform: capitalize; }
      .course-catalog-wrapper .course-catalog__calendar-title-month:first-child {
        margin-top: 30px; }
    .course-catalog-wrapper .course-catalog__no-results {
      margin: auto;
      color: #999;
      font-size: 1.2rem; }
      .course-catalog-wrapper .course-catalog__no-results__term {
        font-style: italic;
        font-weight: bold; }

.course-catalog-wrapper.desktop {
  /*Prevent a possible scrollbr from chaging the width of the page
  and pushing the content
  The padding are the space where the scrollbar resides or empty space if scroll is not present
  */
  margin-right: calc(-1 * (100vw - 100%)); }
