/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.my-education__bg {
  background-color: #F6F5FA;
  height: 350px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  display: none; }
  @media (min-width: 768px) {
    .my-education__bg {
      display: block; } }

.my-education__section {
  padding-top: 10px; }

.course-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; }
  .course-preview__status {
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: 1rem; }
  .course-preview__content {
    width: 100%;
    margin-bottom: 30px; }
  .course-preview__module {
    padding: 20px; }
  .course-preview__container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%; }
    .course-preview__container > * {
      margin-bottom: 20px; }
      .course-preview__container > *:last-child {
        margin-bottom: 100px; }
  .course-preview__cover {
    display: flex;
    height: 400px;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
  .course-preview__header {
    display: flex;
    flex-direction: row;
    align-items: baseline; }
  .course-preview__header-title-wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0; }
  .course-preview__header-title {
    overflow: hidden;
    display: flex;
    color: black;
    font-weight: 700;
    font-size: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .course-preview__header-meta-container {
    display: flex; }
  .course-preview__header-meta-item {
    display: flex;
    align-items: baseline;
    color: #7f7f7f;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 1.5; }
    .course-preview__header-meta-item:not(:first-child) {
      margin-left: 30px; }
    .course-preview__header-meta-item i {
      margin-right: 10px; }
  .course-preview__description {
    line-height: 1.2; }
  .course-preview__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
  .course-preview__action-button:not(:last-child) {
    margin-right: 10px; }
