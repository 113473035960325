/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.search-bar {
  position: relative;
  display: flex;
  padding: 10px; }
  .search-bar-open .search-bar__label {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .search-bar__label {
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
    border-radius: 8px;
    cursor: text; }
  .search-bar__label-text {
    margin-bottom: 7px;
    margin-left: 5px; }
  .search-bar__input {
    flex: 1 1 auto;
    border: none;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 2; }
    .search-bar__input-inset {
      padding-left: 50px !important;
      font-weight: 700; }
  .search-bar__avatar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 8px;
    display: flex;
    align-items: center; }
  .search-bar__icon {
    position: absolute;
    top: 50%;
    right: 20px;
    display: inline;
    height: 100%;
    margin: auto 0;
    color: #ccc;
    line-height: 0;
    pointer-events: none; }
    .search-bar__icon i {
      line-height: 0; }
    .search-bar__icon__loading {
      color: #000; }
  .search-bar__open {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .search-bar__dropdown {
    display: block;
    flex: 1 1 auto;
    flex-direction: column;
    box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
    border-radius: 8px;
    border-top: none;
    background-color: #fff; }
    .search-bar__dropdown__avatar {
      position: relative;
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      height: 100%;
      margin-right: 18px; }
    .search-bar__dropdown__wrapper {
      display: flex;
      margin: -10px 0;
      margin-top: -12px;
      padding: 10px; }
    .search-bar__dropdown__item {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      margin: auto 0;
      border-bottom: 1px solid #f2f2f2;
      padding: 10px 24px;
      font-size: 0.9rem;
      line-height: 1.4; }
      .search-bar__dropdown__item.item-link {
        cursor: pointer; }
        .search-bar__dropdown__item.item-link:hover {
          background-color: #f2f2f2; }
          .search-bar__dropdown__item.item-link:hover .person-avatar {
            box-shadow: 1px 1px 2px rgba(100, 100, 100, 0.2); }
      .search-bar__dropdown__item:last-child {
        border-bottom: none; }
      .search-bar__dropdown__item__active {
        font-weight: 700; }
