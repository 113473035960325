/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.person-avatar {
  position: relative;
  align-items: baseline;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }
  .person-avatar__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: block;
    display: flex;
    border-radius: inherit;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
  .person-avatar__initials {
    margin: auto;
    color: #fff;
    font-weight: 700;
    font-size: 28px;
    user-select: none;
    pointer-events: none; }
  .person-avatar__avatar-layer {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
  .person-avatar__avatar, .person-avatar__avatar-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .person-avatar__notification-count {
    position: absolute;
    bottom: -6px;
    left: -6px;
    z-index: 100;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
    margin-bottom: 14px;
    margin-left: 14px;
    border-radius: 50%;
    background: #a20202;
    color: #fff;
    font-size: 0;
    line-height: 0;
    text-align: center; }
    .person-avatar__notification-count-enter-active {
      transition: all 400ms; }
    .person-avatar__notification-count-enter-active, .person-avatar__notification-count-enter-done {
      opacity: 1;
      width: 22px;
      height: 22px;
      margin-bottom: 0;
      margin-left: 0;
      font-size: 12px;
      line-height: 22px; }
