/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.course-details-wrapper {
  margin-top: 35px;
  padding: 0 20px; }

.course-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;
  margin-top: 20px; }
  .course-preview__files {
    margin: 40px 0; }
  .course-preview__file-button {
    display: block;
    margin: 10px 10px;
    padding: 12px 16px;
    background-color: #e5e5e5;
    color: #000;
    font-size: 1rem; }
    .course-preview__file-button i {
      margin-right: 8px;
      color: #000;
      font-size: 1.2rem; }
  .course-preview__status {
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: 1rem; }
  .course-preview__content {
    width: 80%; }
    .course-preview__content.success {
      background: #81c784; }
  .course-preview__module {
    box-shadow: 1px 2px 3px #ccc;
    margin-bottom: 30px;
    border-radius: 6px;
    padding: 20px;
    background: white; }
  .course-preview__container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%; }
    .course-preview__container > * {
      margin-bottom: 20px; }
      .course-preview__container > *:last-child {
        margin-bottom: 100px; }
  .course-preview__cover {
    display: flex;
    height: 400px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
  .course-preview__header {
    display: flex;
    flex-direction: row;
    align-items: baseline; }
  .course-preview__header-title-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0; }
    .course-preview__header-title-wrapper .course-preview__header-start {
      display: inherit;
      margin-top: 20px; }
  .course-preview__header-title {
    overflow: hidden;
    display: flex;
    color: black;
    font-weight: 700;
    font-size: 1.2rem;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .course-preview__header-meta-container {
    display: flex; }
  .course-preview__header-meta-item {
    display: flex;
    align-items: baseline;
    color: #7f7f7f;
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 1.5; }
    .course-preview__header-meta-item:not(:first-child) {
      margin-left: 30px; }
    .course-preview__header-meta-item i {
      margin-right: 10px; }
  .course-preview__description {
    line-height: 1.2; }
  .course-preview__events {
    margin-top: 40px; }
    .course-preview__events .course-event-details {
      border-top: 1px solid #ccc;
      padding: 20px 0; }
      .course-preview__events .course-event-details button {
        float: right;
        margin-top: -10px; }
  .course-preview__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
    .course-preview__actions button {
      font-weight: 400;
      padding: 12px 25px; }
  .course-preview__action-button:not(:last-child) {
    margin-right: 10px; }
  .course-preview__action-button--signed {
    background-color: #b2b2b2;
    cursor: default; }
    .course-preview__action-button--signed i {
      margin-right: 4px; }
