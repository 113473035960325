/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.course-dates {
  width: 100%; }
  .course-dates__item {
    display: flex;
    box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
    margin-top: 25px;
    border-radius: 10px;
    padding: 15px;
    padding-left: 30px;
    background-color: #fcfcfc; }
