/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.employees-list-container {
  flex: 1 1 auto; }
  .employees-list-container .employees-list {
    margin-top: 30px; }
  .employees-list-container__search-bar-and-actions {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap; }
  .employees-list-container__search-bar-wrapper {
    flex: 1 1 auto; }
  .employees-list-container__actions-container {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    padding: 10px; }
  .employees-list-container__add-employee-button {
    border: none;
    padding: 10px;
    background: none;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer; }
  .employees-list-container__add-employee-button-icon {
    margin-right: 5px; }
  .employees-list-container__status-message {
    margin: 10px;
    color: var(--color-main-dark);
    font-weight: 700;
    text-align: center; }
