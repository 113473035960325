/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.desktop-menu {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: 125px;
  box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
  border-bottom: 1px solid #ccc;
  background: #f9f9f9; }
  @media only screen and (max-width: 750px) {
    .desktop-menu {
      justify-content: flex-start; } }
  .desktop-menu__top-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
    background: var(--color-header-top); }
  .desktop-menu__logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px; }
  .desktop-menu__logo {
    width: 94px;
    fill: #fff; }
    .desktop-menu__logo.theme11, .desktop-menu__logo.theme12, .desktop-menu__logo.theme14, .desktop-menu__logo.theme17, .desktop-menu__logo.theme18 {
      width: 74px; }
    .desktop-menu__logo.theme10 {
      width: 80px; }
  .desktop-menu__search-wrapper {
    display: flex;
    flex-grow: 7;
    flex-shrink: 1;
    justify-content: flex-start;
    margin-left: 15px; }
  .desktop-menu__search {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    max-width: 400px; }
  .desktop-menu__search-input {
    width: 100%;
    height: 48px;
    border: none;
    border-radius: 3px;
    padding: 0 3em 0 0.75em;
    background: var(--color-header-bottom);
    color: var(--color-input-text);
    font-size: 0.9rem;
    line-height: 1; }
    .desktop-menu__search-input::placeholder {
      color: var(--color-input-text); }
  .desktop-menu__search-icon {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3em;
    height: 100%;
    margin-left: 10px;
    color: var(--color-input-text);
    font-size: 1rem; }
  .desktop-menu__buttons {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    align-items: center;
    margin-right: 10px; }
  .desktop-menu__button {
    display: inline-block;
    border: none;
    padding: 16px 8px;
    background: none;
    color: white;
    font-weight: 400;
    font-size: 1.1rem; }
    .desktop-menu__button, .desktop-menu__button:hover, .desktop-menu__button:visited {
      text-decoration: none; }
    .desktop-menu__button i {
      opacity: 0.8;
      margin-right: 7px; }
    .desktop-menu__button:hover i {
      opacity: 1; }
  .desktop-menu__bottom-section {
    display: flex;
    flex: 0 0 60px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
    background: var(--color-header-bottom); }
  .desktop-menu__link {
    display: inline-block;
    height: 59px;
    margin: 0 15px;
    padding: 0 7px;
    color: var(--color-header-text) !important;
    font-size: 1rem;
    line-height: 60px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase; }
    .desktop-menu__link--active {
      border-bottom: 2px solid var(--color-header-link-underline); }
