/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.checklist-item {
  display: flex;
  align-items: center;
  margin-top: 10px; }
  .checklist-item__status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 2px solid #7f7f7f;
    border-radius: 100%;
    background: #fcfcfc;
    font-size: 15px; }
  .checklist-item__title {
    margin-left: 10px; }
  .checklist-item--passed .checklist-item__status {
    border-color: #2d6017;
    background-color: #9cc79c;
    color: #2d6017; }
