/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.news-list__news-body a {
  display: inline-block; }

.news-list__news-body a,
.btn {
  box-sizing: border-box;
  box-shadow: 1px 1px 4px rgba(100, 100, 100, 0.4);
  border-radius: 5px;
  padding: 8px 15px;
  background: var(--color-main-dark);
  color: white;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 1.5;
  cursor: pointer; }
  .news-list__news-body a:hover,
  .btn:hover {
    box-shadow: none;
    box-shadow: 1px 1px 4px #fff; }
  .news-list__news-body a--icon,
  .btn--icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 0; }
  .news-list__news-body a--light-background,
  .btn--light-background {
    background-color: #fcfcfc;
    color: var(--color-main-dark); }
  .news-list__news-body a--flat,
  .btn--flat {
    box-shadow: none;
    background-color: transparent;
    color: var(--color-main-dark); }
