/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.events-list {
  flex: 1 1 auto;
  padding: 20px; }
  .events-list__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px; }
  .events-list__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    color: var(--color-main-dark);
    line-height: 1.2; }
    .events-list__date__day {
      font-size: 2em; }
    .events-list__date__month {
      margin-top: -4px;
      font-size: 0.8rem; }
  .events-list__avatar {
    flex: 0 0 40px;
    margin-top: 5px;
    margin-right: 25px;
    margin-left: 10px; }
    .events-list__avatar .person-avatar {
      width: 45px;
      height: 45px; }
  .events-list__card {
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
    border-radius: 5px;
    padding: 10px;
    background-color: #fff; }
    .events-list__card__contents {
      flex: 1 1 auto;
      display: flex; }
  .events-list__text {
    position: relative;
    width: 100%;
    margin-top: 10px;
    padding-top: 10px; }
    .events-list__text::after {
      content: '';
      position: absolute;
      top: 0;
      right: -1000px;
      left: -1000px;
      display: block;
      border-top: 1px solid #e9e9e9; }
    .events-list__text:first-child {
      margin-top: 0;
      padding-top: 0; }
      .events-list__text:first-child::after {
        border-top: none; }
    .events-list__text__title {
      color: var(--color-main-dark);
      font-weight: 600; }
      .events-list__text__title:hover {
        color: var(--color-main-dark);
        text-decoration: underline; }
    .events-list__text__description {
      font-size: 0.8rem;
      line-height: 1.4; }
    .events-list__text__location {
      font-size: 0.8rem; }
  .events-list__person-name {
    margin-bottom: 6px;
    color: #000;
    font-size: 1rem; }
    .events-list__person-name:hover {
      text-decoration: underline; }
