/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.competence-card {
  box-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
  margin: 10px;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 20px;
  background: white; }
  @media screen and (max-width: 768px) {
    .competence-card {
      margin: 10px 0; } }
  .competence-card.compact .course-preview__action-button {
    box-shadow: none;
    border: 1px solid #ccc;
    background-color: #efefef;
    color: #666; }
    .competence-card.compact .course-preview__action-button:hover {
      background-color: inherit; }
  .competence-card.employee {
    border: none; }
    .competence-card.employee .grid-x {
      width: 100%; }
      .competence-card.employee .grid-x .course-grade {
        font-size: 0.8rem;
        text-align: right; }
  .competence-card .sub h3 {
    border-bottom: 1px solid #ccc;
    font-size: 0.9rem; }
  .competence-card .button {
    text-align: right; }
  .competence-card .competence-card-sub {
    margin: 4px 0;
    padding: 4px;
    font-size: 0.9rem; }
  .competence-card .small-header {
    width: 100%;
    font-size: 0.8rem; }
