/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.modal {
  position: fixed;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 0 20px; }
  .modal__backdrop {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); }
  .modal__content {
    position: fixed;
    z-index: 1;
    /*
      overflow: visible;
      overflow: hidden;
      overflow-x: hidden;
      overflow-x: hidden;
      overflow-y: auto;
      overflow-y: visible;
     */
    flex: 0 0 auto;
    width: 100%;
    height: fit-content;
    max-height: 100%;
    margin: auto;
    margin-bottom: 40px;
    border-radius: 10px;
    border-radius: 8px;
    transform: translate(0%, 0%);
    top: 20px; }
    @media (min-width: 768px) {
      .modal__content {
        top: 10%; } }
    @media (min-width: 576px) {
      .modal__content {
        top: 20%;
        left: 50%; } }
    @media (min-width: 576px) {
      .modal__content {
        transform: translate(-50%, 0%); } }
    @media (min-width: 576px) {
      .modal__content {
        width: auto; } }
  .modal__loading-text {
    /* Absolute center */
    position: fixed;
    top: 50%;
    left: 50%;
    color: #a1a1a1;
    font-size: 3em;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%); }
  @media screen and (-webkit-min-device-pixel-ratio: 2) {
    .modal__content {
      margin-top: 0; } }
  @media screen and (max-width: 768px) {
    .modal {
      padding: 0; }
      .modal__content {
        width: 100%;
        margin-top: 30px; } }
