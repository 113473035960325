/*
  stylelint-disable
*/
:export {
  colorblue: #078ada;
  colorcompleted: #81c784;
  colornotcompleted: #ef5350;
  colorgrey80: #ccc; }

.course-date {
  position: relative;
  display: flex;
  width: 100%; }
  .course-date__date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-main-dark); }
    .course-date__date-day {
      font-size: 2.4rem;
      line-height: 2.4rem; }
    .course-date__date-month {
      font-size: 0.8rem; }
  .course-date__details {
    margin: 0 30px; }
  .course-date__title {
    margin-bottom: 5px;
    font-size: 1rem; }
  .course-date__location, .course-date__hours {
    font-size: 0.8rem; }
  .course-date__button {
    align-self: center;
    margin-right: 0;
    margin-left: auto; }
